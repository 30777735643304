import axios from 'axios';


const url='https://mydigitalcard-admin.kitlabs.us/api/digital-card';
 export const callApi = async (formData) => {
  
   console.log("FormData",formData);
   const storedToken = localStorage.getItem('token');
  try {
    const response = await axios.post(url, formData, {
      headers: {
        'Authorization': `Bearer ${storedToken}`,
        "Content-Type": "multipart/form-data",
 
      }
    });
     
     console.log("dataapi",response.data.data.business_card_id);
     return response.data;
   } catch (error) {
 
     console.error(error);
     throw error;
   }
};

export const handleFormSubmit = async (formData) => {
  const storedToken = localStorage.getItem('token');
  try {
    const response = await axios.post(url,formData,{
      headers: {
        'Authorization': `Bearer ${storedToken}`,
        "Content-Type": "multipart/form-data",
      }
      });
    console.log(response.data);
   
    
    
  } catch (error) {
    console.error(error);
   
  }

};


