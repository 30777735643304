import React from "react";
import logo from "../images/logo.svg";



import check from '../images/check-fill.svg';
import {  useNavigate} from "react-router-dom";
import arrowNext from "../images/arrow-next.svg"

const QRCode = ({path}) => {
const image='https://mydigitalcard-admin.kitlabs.us'
  const localStorageQrCode=localStorage.getItem("image")
  const localStoragefirstName=localStorage.getItem("firstname");
  const localStorageSecondName=localStorage.getItem("Secondname");
 
const navigate=useNavigate();
  const handleNavigate=()=>{
   
      // localStorage.removeItem('token');
      // localStorage.removeItem('firstname');
      // localStorage.removeItem('Secondname');
      // localStorage.removeItem('image');
      // localStorage.removeItem('id');
      // localStorage.removeItem('login');
      // window.location.reload(); 

    navigate("/dashboard")
  }
   
  return (
    <div className="wrapper">
    <div className="container">
    <section className="wrapper-body">
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div className="col-md-12 h-100">
            <div className="main-content h-100">
              <div className="row h-100 flex-row-reverse">
                {/* <div className="col-md-4 account-left-bg h-100">
                  <div className="account-left h-100">
                    {/* <!-- <div className="account-visual">
                                    <img src="images/my-card.png" alt="Digital Card" className="img-fluid">
                                </div> --> 
                  </div>
                </div> */}
                <div className="col-xl-12 h-100">
                  <div className="digital-card-form h-100">
                    <div className="digital-card-inputs">
                      <div className="site-logo">
                        <a href="sign-in.html">
                          <img src={logo} alt="Logo" />
                        </a>
                        {/* <button onClick={handleNavigate} className="btn primary-btn ms-2" >
                          logout{" "}
                         
                           </button> */}
                      </div>
                      <div className="details">
                      
                        <div className="details-header text-center">
                          <h3>Congratulations!</h3>
                          <p className="d-flex align-items-center justify-content-center">
                            Your card is ready{" "}
                            <img src={check}alt="" />{" "}
                          </p>
                        </div>
                        <div className="my-card">
                          <div className="my-card-visual">
                            <img src={`${image}${localStorageQrCode}`} alt="" />
                            <br />
                            <span>Point your camera to QR code</span>
                          </div>
                          <div className="my-card-body">
                            <h4>{localStoragefirstName} {localStorageSecondName}</h4>
                          </div>
                        </div>
                      </div>
                      <div className="digital-card-action justify-content-center">
                      <button onClick={handleNavigate} className="btn primary-btn ms-2" >
                          Finish{" "}
                         
                           </button>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
    </div>
  );
};

export default QRCode;
