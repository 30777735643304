import React from "react";
import image2 from "../images/logo.svg";
import { Link } from "react-router-dom";

import SideContent from "./SideContent";

const ForgotPassword = () => {
  return (
    <section className="wrapper-body">
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div className="col-md-12 h-100">
            <div className="main-content h-100">
              <div className="row h-100">
                <SideContent />
                <div className="col-xl-6 h-100">
                  <div className="account-right h-100">
                    <div className="site-logo text-center">
                      <Link to="/">
                        <img src={image2} alt="Logo" />
                      </Link>
                    </div>
                    <div className="account-form">
                      <div className="account-form-header">
                        <h3>Change Password</h3>
                        <p>Please fill in your details to reset password.</p>
                      </div>
                      <div className="account-inputs">
                        <form action="sign-in.html">
                          <div className="mb-3 mt-3 cst-input">
                            <input
                              type="email"
                              className="form-control email-input"
                              id="email"
                              placeholder="Enter email"
                              name="email"
                            />
                          </div>
                          <div className="mb-3 cst-input">
                            <input
                              type="password"
                              className="form-control pass-input"
                              id="pwd"
                              placeholder="Enter new password"
                              name="pswd"
                            />
                          </div>
                          <div className="mb-5 cst-input">
                            <input
                              type="password"
                              className="form-control pass-input"
                              id="pwd"
                              placeholder="Confirm password"
                              name="pswd"
                            />
                          </div>
                          <div className="register mb-3">
                            <button
                              type="submit"
                              className="btn primary-btn w-100"
                            >
                              Save
                            </button>
                            <br />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
