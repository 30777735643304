import React from 'react'
import young from "../images/young-man.jpg";
import arrowBack from "../images/arrow-back.svg"
import arrowNext from "../images/arrow-next.svg"
import news from "../images/News.jpg";
import { useNavigate } from 'react-router-dom';

const NewList = () => {
  const navigate=useNavigate();
  const handle=()=>{
    navigate("/news");
  }
  return (
    <div className="wrapper h-auto py-4 wrapper-top-margin">
    <div className="container">
      {/* <!--Wrapper body--> */}
      <section className="wrapper-body h-100 pb-4">
        <div className="container-fluid h-100">
          <div className="row h-100">
            <div className="col-md-12 h-100">
              <div className="main-content h-100">
                <div className="row h-100 justify-content-center">
                  {/* <!-- <div className="col-md-4 account-left-bg h-100 d-none d-xl-block">
                            <div className="account-left h-100">
                                 <div className="account-visual">
                                    <img src="images/my-card.png" alt="Digital Card" className="img-fluid">
                                </div> 
                            </div>
                        </div> --> */}
                  <div className="col-xl-12 h-100">
                    <div className="dashborad h-100">
                      <div className="row justify-content-center">
                        <div className="col-12 col-md-10">
                          {" "}
                          <div className="details">
                            <div className="details-header">
                              <h3>News</h3>
                        <div className="container mt-3">
                        <img src={news} className="img-fluid list" alt="Responsive image"/>
                        <div className="d-flex justify-content-between mt-3">
                            <p>Development</p>
                            <p>22 January, 2024</p>
                        </div>
                        <div className="">
                            <h4>How to choose the right Laptop</h4>
                            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fugiat unde ullam quibusdam iure amet enim dolorem quo, provident nihil expedita vel quasi ut nam, dolores culpa eaque distinctio molestiae vero? Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam alias tenetur qui, iusto animi, quod cum numquam, dolor libero reprehenderit modi dolore molestias nemo laboriosam asperiores ea quae. Facere, ratione. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sapiente dolorem natus saepe in illum perspiciatis repellat? Obcaecati ad, quasi minima perferendis eaque mollitia sed quaerat cupiditate, necessitatibus, alias ut expedita? Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores voluptatem molestias architecto id natus obcaecati, libero atque maiores voluptas, sint consectetur pariatur non molestiae veritatis soluta culpa quo labore quae?</p>
                        </div>
                        <div className="user-pic d-flex mt-5">
                                          <span>
                                            <img src={young} />
                                          </span>
                                          <div className="d-blox ms-3">
                                            <h5>Uma Shankar</h5>
                                            <p className="m-0 text-start">
                                              President
                                            </p>
                                          </div>
                                        </div>
                                        <div className="digital-card-action">
                          <button  className="btn primary-btn" onClick={handle}>
                            <img src={arrowBack} alt="arrow-left"/> Back
                          </button>
                          <button
                             className="btn primary-btn"
                          >
                            <img src={arrowNext} alt="arrow-right"/> Next
                          </button>
                        </div>

                        </div>
                             
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  )
}

export default NewList
