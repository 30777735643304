import React, { useState } from "react";
import arrowBack from "../images/arrow-back.svg";
import logo from "../images/logo.svg";
import { Link } from "react-router-dom";
const CompanyInfo = ({
  handleSubmit,
  handleNextSection,
  register,
  onSubmit,
  countryOptions,
  handleSkipSection,
 watch,
 errors
}) => {
  const [color,setcolor]=useState("grey");
  function colorChange(){
      setcolor("black");
  }
  const [focused, setFocused] = useState(false);
  
  const handleFocus = () => {
    setFocused(true);
  };
  
  const handleBlur = () => {
    setFocused(false);
  };
  return (
    <div className="container">
    <section className="wrapper-body">
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div className="col-md-12 h-100">
            <div className="main-content h-100">
              <div className="row h-100 flex-row-reverse">
                {/* <div className="col-md-4 account-left-bg h-100">
                  <div className="account-left h-100">
                    {/* <!-- <div class="account-visual">
                                            <img src="images/my-card.png" alt="Digital Card" class="img-fluid">
                                        </div> --> 
                  </div>
                </div> */}
                <div class="col-xl-12 h-100">
                  <div class="digital-card-form h-100">
                    <div class="digital-card-inputs">
                      <div class="site-logo">
                      <Link to="/complete-profile">
                            <img src={logo} alt="Logo" />
                          </Link>
                      </div>
                      <div className="details company-details ">
                        <div className="details-header">
                          <h3>Fill in your Company Details</h3>
                          <p>Please fill in your details to continue. </p>
                          <Link 
                             onClick={handleSubmit(() =>
                              handleSkipSection(false))}
                            >
                              Skip
                            </Link>
                        </div>
                        <div className="details-form pt-3">
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-4 cst-input company-name">
                                  <input
                                    type="text"
                                    id="company_name"
                                    {...register("company_name")}
                                    className="form-control email-input"
                                    placeholder="Company Name"
                                    onFocus={handleFocus}
                                      onBlur={handleBlur}
                                  />
                                    {!focused && !watch('company_name') && (
        <span style={{ position: 'absolute', top: '31%', left : '172px', transform: 'translateY(-50%)', color: 'red' }}>*</span>
      )}
                                </div>
                                </div>
                                <div className="col-md-6">
                                <div className="mb-4 cst-input companytagline">
                                  
                                    <input
                                    type="text"
                                    id="company_tagline"
                                    {...register("company_tagline")}
                                    className="form-control email-input"
                                    placeholder="Company Tagline"
                                  />
                                </div>
                                </div>
                                <div className="col-md-6">
                                <div className="mb-4 cst-input address">
                                  {/* <textarea className="form-control" rows="2" id="comment" name="text"></textarea> */}
                                  <textarea
                                    type="text"
                                    className="form-control"
                                    row="2"
                                    id="address_line_1"
                                    {...register("address_line_1")}
                                    placeholder="Address Line 1"
                                  />
                                </div>
                                </div>
                                <div className="col-md-6">
                                <div className="mb-4 cst-input address">
                                <textarea
                                    type="text"
                                    className="form-control"
                                    id="address_line_2"
                                    {...register("address_line_2")}
                                    placeholder="Address Line 2"
                                  />
                                  
                                </div>
                                </div>
                               
                                <div className="col-md-6">
                                <div className="mb-4 cst-input">
                                <select
                                    className="form-select select-with-image py-2" style={{color:color}}
                                    id="country" 
                                    {...register("country")}
                                    // onChange={handleCountryChange}
                                    onChange={colorChange}
                                  >
                                    <option value=""  >Select a country</option>
                                    {countryOptions}
                                  </select>
                                </div>
                                </div>
                                <div className="col-md-6">
                                <div className="mb-4 cst-input state ">
                                 
                                   <input
                                    type="text"
                                    id="state"
                                    {...register("state", {
                                      
                                      pattern: {
                                        value: /^[A-Za-z]+$/,
                                        message: "Please enter a valid state name",
                                      },
                                    })}
                                    name="state"
                                    className="form-control email-input"
                                    placeholder="State"
                                  />
                                  {errors.state && (
  <span className="text-danger">{errors.state.message}</span>
)}
                                </div>
                                </div>
                                <div className="col-md-6">
                                <div className="mb-4 cst-input state ">
                                  
                                <input
                                    type="text"
                                    id="city"
                                    {...register("city", {
                                     
                                      pattern: {
                                        value: /^[A-Za-z]+$/,
                                        message: "Please enter a valid city name",
                                      },
                                    })}
                                    name="city"
                                    className="form-control email-input"
                                    placeholder="City"
                                  />
                                   {errors.city && (
  <span className="text-danger">{errors.city.message}</span>
)}
                                </div>
                              </div>
                              
                                <div className="col-md-6">
                                <div className="mb-4 cst-input state ">
                                  <input
                                    type="text"
                                    id="postal_code"
                                    maxLength="6"
                                    {...register("postal_code", {
                                      
                                      pattern: {
                                        value: /^\d{4}(?:\d{2})?\s?$/,

                                        message: "Please enter a valid postal code",
                                      },
                                    })}
                                    name="postal_code"
                                    className="form-control email-input"
                                    placeholder="Postal Code"
                                  />
                                    {errors.postal_code && (
  <span className="text-danger">{errors.postal_code.message}</span>
)}
                                </div>
                                
                              </div>
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="mb-4 cst-input linkedin-icon">
                                    <input
                                      type="url"
                                      id="personal_social_linkedin"
                                      {...register("personal_social_linkedin", {
                                        pattern: {
                                          value: /^(https?:\/\/)?(www\.)?linkedin\.com\/.*$/, // Regular expression for LinkedIn URL
                                          message: "Please enter a valid LinkedIn URL",
                                        },
                                      })}
                                      className="form-control email-input"
                                      placeholder="LinkedIn URL"
                                    />
                                                                     {errors.personal_social_linkedin && (
  <span className="text-danger">{errors.personal_social_linkedin.message}</span>
)}
    
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="mb-4 cst-input twitter-icon">
                                    <input
                                      type="url"
                                      id="personal_social_twitter"
                                      {...register("personal_social_twitter", {
                                        pattern: {
                                          value: /^(https?:\/\/)?(www\.)?twitter\.com\/(?:#!\/)?(\w+)\/?$/,
                                          message: "Please enter a valid Twitter profile URL",
                                        },
                                      })}
                                      className="form-control email-input"
                                      placeholder="Twitter URL"
                                    />
                                       {errors.personal_social_twitter && (
  <span className="text-danger">{errors.personal_social_twitter.message}</span>
)}
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="mb-4 cst-input facebook-icon">
                                    <input
                                      type="url"
                                      id="personal_social_facebook"
                                      {...register("personal_social_facebook", {
                                        pattern: {
                                          value: /^(https?:\/\/)?(www\.)?(facebook|fb)\.com\/.*$/,
                                          message: "Please enter a valid Facebook profile URL",
                                        },
                                      })}
                                      className="form-control email-input"
                                      placeholder="Facebook URL"
                                    />
                                         {errors.personal_social_facebook && (
  <span className="text-danger">{errors.personal_social_facebook.message}</span>
)}
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="mb-4 cst-input instagram-icon">
                                    <input
                                      type="url"
                                      id="personal_social_instagram"
                                      {...register("personal_social_instagram", {
                                        pattern: {
                                          value: /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9_]+$/,
                                          message: "Please enter a valid Instagram profile URL",
                                        },
                                      })}
                                      className="form-control email-input"
                                      placeholder="Instagram URL"
                                    />
                                    {errors.personal_social_instagram && (
  <span className="text-danger">{errors.personal_social_instagram.message}</span>
)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="digital-card-action">
                        <button
                          onClick={() => handleNextSection(true)}
                          className="btn primary-btn"
                        >
                          <img src={arrowBack} alt="arrow-left" /> Back
                        </button>
                        <button type="submit" onClick={handleSubmit(onSubmit)} className="btn primary-btn">
                        Finish
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
  );
};

export default CompanyInfo;
