import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "./Button";
import logo from "../images/logo.svg";
import placeholder from "../images/upload-placeholder1.jpg";

const HeadShot = ({
  handleSubmit,
  handleNextSection,
  register,
  onSubmit,
  imageSrc,
  setImageSrc,
  setOriginalFileName,
  handleSkipSection
}) => {


 
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    console.log(file);

    if (file) {
    
      const imageUrl = URL.createObjectURL(file);
         
      setImageSrc(imageUrl);
   
     
    }
 
    
    setOriginalFileName(file);
  };

  useEffect(() => {
    // Update the image source when imageSrc changes
    setImageSrc(imageSrc);
  }, [imageSrc]);
  
  return (
    <>
      <div className="container">
      <section className="wrapper-body">
        <div className="container-fluid h-100">
          <div className="row h-100">
            <div className="col-md-12 h-100">
              <div className="main-content h-100">
                <div className="row h-100 flex-row-reverse">
                  {/* <div className="col-md-4 account-left-bg h-100 d-none d-xl-block">
                    <div className="account-left h-100 ">
                      {/* <!-- <div className="account-visual">
                                    <img src="images/my-card.png" alt="Digital Card" className="img-fluid">
                                </div> --> 
                    </div>
                  </div> */}
                  <div className="col-xl-12 h-100">
                    <div className="digital-card-form h-100">
                      <div className="digital-card-inputs">
                        <div className="site-logo">
                          <Link to="/complete-profile">
                            <img src={logo} alt="Logo" />
                          </Link>
                        </div>
                        <div className="details">
                          <div className="details-header">
                            <h3>Add Headshot</h3>
                            <p><span style={{ color: 'red' }}>*</span> Please attach a profile image of you.</p>
                            <Link
                              onClick={handleSubmit(() =>
                                handleSkipSection(false)
                              )}
                            >
                              Skip
                            </Link>
                          </div>
                          <div className="details-form">
                            <span className="text-center d-block mb-3">
                              Upload image
                            </span>
                            <form onSubmit={handleSubmit(onSubmit)} >
                              <div className="image-upload">
                                <img
                                  id="upload"
                                  src={imageSrc || placeholder} // Use uploaded image or placeholder
                                  alt="Place"
                                />
                                <input
                                  type="file"
                                  id="head_shot"
                                  onChange={(e) => {
                                    handleImageChange(e);
                                    register("head_shot"); // Assuming register is passed as a prop
                                  }}
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="mt-3 text-center">
                      
                                </div>
                        <div className="digital-card-action">
                       
                          <Button
                            onClick={handleSubmit(() =>
                              handleNextSection(false) 
                            )}
                          >
                            Next
                          </Button>
                         
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>   
    </>
  );
};

export default HeadShot;
