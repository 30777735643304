import React from 'react'
import { Link } from 'react-router-dom'

const Nopage = () => {
  return (
	<div className="wrapper">
    <section className="wrapper-body">
	<div className="container-fluid h-100">
		<div className="row h-100">	
		<div className="col-md-12 h-100 ">
		<div className="col-sm-12 text-center">
		<div className="four_zero_four_bg">
			<h1 className="text-center ">404</h1>
		</div>
		<div className="contant_box_404">
		<h3 >
		Look like you're lost
		</h3>
		
		<p>the page you are looking for not available!</p>
		
		<Link to ="/" className="link_404">Go to Home</Link>
	</div>
		</div>
		</div>
		</div>
	</div>
</section>
</div>
  )
}

export default Nopage
