import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from "../images/card-white-logo.svg";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const closeNavbar = () => {
    setIsOpen(false);
  };

  const logout = () => {
    setLogoutDialogOpen(true);
  };

  const handleLogoutConfirmed = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('firstname');
    localStorage.removeItem('Secondname');
    localStorage.removeItem('image');
    localStorage.removeItem('id');
    localStorage.removeItem('login');
    localStorage.removeItem('email');
    window.location.reload();
  };

  const handleLogoutCanceled = () => {
    setLogoutDialogOpen(false);
  };

  return (
    <>
      <nav className="navbar navbar-expand-sm  navigation-bar fixed-top">
        <div className="container">
          <Link className="navbar-brand" to="/dashboard">
            <img src={logo} alt=""/>
          </Link>
          <button className="navbar-toggler background" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar" onClick={toggleNavbar}>
            <span className="navbar-toggler-icon "></span>
          </button>
          <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="collapsibleNavbar">
            <ul className="navbar-nav ms-auto me-3">
              <li className="nav-item">
                <Link className="nav-link" to="/my-card" onClick={closeNavbar}>My Digital Card</Link>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown">My Profile</a>
                <ul className="dropdown-menu">
                  <li><Link className="dropdown-item" to="/personal-details" onClick={closeNavbar}>Personal</Link></li>
                  <li><Link className="dropdown-item" to="/official-details" onClick={closeNavbar}>Official</Link></li>
                </ul>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/mycontact" onClick={closeNavbar}>My Contacts</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact-us" onClick={closeNavbar}>Contact Us</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/resetpassword" onClick={closeNavbar}>Change Password</Link>
              </li>
            </ul>
            <div className="d-flex ">
              <Button className="btn primary-btn py-1 px-3" variant="contained" onClick={logout}   style={{ backgroundColor: 'green', color: 'white' }}>Sign Out</Button>
            </div>
          </div>
        </div>
      </nav>

      {/* Logout Dialog */}
      <Dialog
        open={logoutDialogOpen}
        onClose={handleLogoutCanceled}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ style: { maxWidth: '500px', width: '100%' } }}
      >
        <DialogTitle id="alert-dialog-title"  style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Confirm Logout</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"style={{ fontWeight: 'bold' }}>
            Are you sure you want to log out?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogoutCanceled} style={{ color: 'green', fontWeight: 'bold' }}>Cancel</Button>
          <Button onClick={handleLogoutConfirmed} style={{ color: 'green', fontWeight: 'bold' }} autoFocus>LogOut</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default NavBar;
