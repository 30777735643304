import React from 'react'
import image1 from "../images/Sagar1.png";
import image2 from "../images/Sagar2.png";
import image3 from "../images/Sagar3.png";
const AccountDelete = () => {
  return (
    <div className='container'>
    <h2>Privacy Policy - Account Deletion</h2>
    <p>You can delete your My Digital Card Account in 3 simple steps.</p>
    
    <ul>
            <strong>Step 1:</strong> Click on Side Menu.
            <br/>
            <img src={image1} alt="Screenshot of Step 1" style={{ height: '800px', }} />
        </ul>
        <ul>
        <strong>Step 2:</strong> After that Select the delete Account option from the Menu.
            <br/>
            <img src={image2} alt="Screenshot of Step 2" style={{ height: '800px', }}/>
        </ul>
        <ul>
        <strong>Step 3:</strong> And Click DELETE MY ACCOUNT
            <br/>
            <img src={image3} alt="Screenshot of Step 3" style={{ height: '800px', }}/>
        </ul>
   
       
    <p>This is all that is needed to delete your My Digital Card Account.</p>
    <h3>What data will remain after the user deletes their account:</h3>
    <p>There will be no data remaining of the user once the user clicks "Delete Account".</p>
</div>
  )
}

export default AccountDelete
