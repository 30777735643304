import React, { useLayoutEffect, useState } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import { useForm } from "react-hook-form";
import axios from "axios";
import eye from "../images/eye.svg";
import { jwtDecode } from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";
import { ColorRing } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import eyeclose from "../images/eyeclose.svg";
import image2 from "../images/logo.svg";
import SideContent from "./SideContent";
const Login = ({parent,login,socialRegister,detailUrl,contacts }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [passwordVisible, setPasswordVisible] = useState({
    Password: false,
    
});
const id = getParameterByName("id");
  console.log("datatrry",id)
  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  const handleVisibilityToggle = (field) => {
    setPasswordVisible((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };
  const notify = (data) =>
    toast.error(data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  useLayoutEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      console.log(data);
      //  navigate("/profile");
      const response = await axios.post(login, data);
      
      parent( response.data.data.token);
      if(id && response.data.message==="Logged In Successfully"){
        await axios.post(contacts,{
        
         linked_business_card_id: id,
        },{headers: {
         'Authorization': `Bearer ${response.data.data.token}`
        }})

        const response2 = await axios.get(`${detailUrl}?id=${id}`);
        const cardDetails = response2.data.data;
  
        const generateVCardData = (first_name, last_name, phone_1, email) => {
          return `BEGIN:VCARD\r\nVERSION:3.0\r\nFN:${first_name} ${last_name}\r\nTEL:${phone_1}\r\nEMAIL:${email}\r\nEND:VCARD`;
        };
  
        const vcardData = generateVCardData(
          cardDetails.first_name,
          cardDetails.last_name,
          cardDetails.phone_1,
          cardDetails.email
        );
  
        const downloadVCard = (vcardData) => {
          const blob = new Blob([vcardData], { type: "text/vcard" });
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "contact.vcf");
          link.click();
        };
        
        downloadVCard(vcardData);
        navigate("/dashboard");
      }
      navigate("/dashboard");
    } catch (error) {
      // Handle any errors
      notify("You Email/Passowrd is incorrect");
     
      console.error(error.message);
      

    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };
  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      console.log(credentialResponse);
      const { credential, id } = credentialResponse;
  
      const decodedToken = jwtDecode(credential);
      const { email, sub } = decodedToken;
  
      const data = {
        email,
        socialID: sub,
        register_type: "web",
        device_type: "web",
        role_type: 2,
      };
  
      console.log(data);
      setLoading(true);
      const response = await axios.post(socialRegister, data);
  
      console.log(response.data.message);
  
      parent(response.data.data.token);
      localStorage.setItem("email",data.email)
      if (id && response.data.message === "Logged In Successfully") {
        await axios.post(
          contacts,
          {
            linked_business_card_id: id,
          },
          {
            headers: {
              Authorization: `Bearer ${response.data.data.token}`,
            },
          }
        );
  
        const response2 = await axios.get(`${detailUrl}?id=${id}`);
        const cardDetails = response2.data.data;
  
        const generateVCardData = (first_name, last_name, phone_1, email) => {
          return `BEGIN:VCARD\r\nVERSION:3.0\r\nFN:${first_name} ${last_name}\r\nTEL:${phone_1}\r\nEMAIL:${email}\r\nEND:VCARD`;
        };
  
        const vcardData = generateVCardData(
          cardDetails.first_name,
          cardDetails.last_name,
          cardDetails.phone_1,
          cardDetails.email
        );
  
        const downloadVCard = (vcardData) => {
          const blob = new Blob([vcardData], { type: "text/vcard" });
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "contact.vcf");
          link.click();
        };
  
        downloadVCard(vcardData);
        navigate("/dashboard");
      } else if (response.data.message === "Logged In Successfully") {
        navigate("/dashboard");
      } else {
        navigate("/welcome");
      }
    } catch (error) {
      // Handle any errors
      notify("your email is already registered");
      console.log(error.message);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };
  

  const handleGoogleFailure = (error) => {
    console.error(error);
  };

  return (
    <>
   
      {loading && (
        <div className="loading-container">
          <div className="overlay">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-ring-loading"
              colors={["#61b33b", "#61b33b", "#61b33b", "#61b33b", "#61b33b"]}
            />
          </div>
        </div>
      )} <ToastContainer/>
      <div className="wrapper">
      <section className="wrapper-body">
        <div className="container-fluid h-100">
          <div className="row h-100">
            <div className="col-md-12 h-100">
              <div className="main-content h-100">
                <div className="row h-100">
                  <SideContent />
                  <div className="col-xl-6 h-100">
                    <div className="account-right h-100">
                      <div className="site-logo text-center">
                        <Link to="/">
                          <img src={image2} alt="Logo" />
                        </Link>
                      </div>
                      <div className="account-form">
                        <div className="account-form-header">
                          <h3>Sign In</h3>
                          <p>
                            Please fill in your details to continue sign in.
                          </p>
                        </div>
                        <div className="account-inputs">
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-3 mt-3 cst-input">
                            <label htmlFor="email">Email <span style={{ color: 'red' }}>*</span></label>
                              <input
                                type="email"
                                id="email"
                                {...register("email", { required: true })}
                                autoComplete="off"
                                className="form-control email-input"
                                placeholder="Enter email"
                              />
                              {errors.email && (
                                <span className="error-message">
                                  This field is required
                                </span>
                              )}
                            </div>
                            <div className="mb-3 cst-input password-eye">
                            <label htmlFor="Password">Password <span style={{ color: 'red' }}>*</span></label>
                            <span
                                  className={`eye-icon ${
                                    passwordVisible.Password ? "visible" : ""
                                  }`}
                                  onClick={() =>
                                    handleVisibilityToggle("Password")
                                  }
                                >
                                  {passwordVisible.Password ? (
    <img src={eyeclose} alt="close-eye-icon" />
  ) : (
    <img src={eye} alt="eye-icon" />
  )}
                                </span>
                                <input
                                  type={
                                    passwordVisible.Password
                                      ? "text"
                                      : "password"
                                  }
                                id="password"
                                className="form-control pass-input"
                                {...register("password", { required: true })}
                                placeholder="Enter password"
                              />
                              {errors.password && (
                                <span className="error-message">
                                  This field is required
                                </span>
                              )}
                            </div>
                            <div className="mb-3 text-end">
                              <Link to="/forgot-password" className="btn btn-text">
                                Forgot password
                              </Link>
                            </div>
                            <div className="register">
                              <button
                                type="submit"
                                className="btn primary-btn w-100"
                              >
                                Sign In
                              </button>
                              <span>
                                {" "}
                                Don’t have an account?{" "}
                                <Link to="/sign-up">Sign Up</Link>
                              </span>
                            </div>
                            <div className="seperator">
                              <span>or</span>
                            </div>
                            <div className="google-btn">
                              {/* <!-- <a href="" class="btn"><img src="images/google-icon.svg" alt="Google Logo">Continue with
                                                            Google</a> --> */}
                              <GoogleOAuthProvider clientId="32605703320-gavotov5aioaillt7fjh4b40i4h98eoo.apps.googleusercontent.com">
                                <GoogleLogin
                                  onSuccess={handleGoogleSuccess}
                                  onFailure={handleGoogleFailure}
                                />
                              </GoogleOAuthProvider>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
    </>
  );
};

export default Login;
