import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import young from "../images/default-user-img.webp";
import { ColorRing } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const Personal = ({personalUrl,updatePersonal}) => {
  const image='https://mydigitalcard-admin.kitlabs.us'
  const { register, handleSubmit,setValue , formState: { errors }, } = useForm();

  const [loading, setLoading] = useState();

  const [imageSrc, setImageSrc] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const [Image,setImage]=useState();
  
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const storedToken = localStorage.getItem('token');
      try {
        setLoading(true);
        const response = await axios.get(`${personalUrl}`, {
          headers: {
            'Authorization': `Bearer ${storedToken}`
          }
        });
       
        
        setValue("first_name", response.data.data.first_name);
        setValue("last_name", response.data.data.last_name);
        setValue("email", response.data.data.email);
        setValue("phone1", response.data.phone_1);
        setValue("phone2", response.data.phone_2);
        setImage(response.data.data.head_shot ? `${image}${response.data.data.head_shot}` : young);

        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    
    fetchData();
    setTimeout(() => {
      setLoading(false); // Set loading to false after a certain delay
    }, 900); 
  }, [personalUrl,setValue]); 

  const notify = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      fontWeight: "bold",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const notify2 = (message1) =>
    toast.error(message1, {
      position: "top-right",
      autoClose: 5000,
      fontWeight: "bold",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

    const handleFileChange = (e) => {
      const file = e.target.files[0];
      console.log(file);
  
      if (file) {
        const imageUrl = URL.createObjectURL(file);
  setImageSrc(imageUrl);
       
      }
      setSelectedFile(file);
    };
  

  const onSubmit = async (data) => {
    const storedToken = localStorage.getItem('token');
    try {
      setLoading(true);
    
      const formData = new FormData();
      formData.append("first_name", data.first_name);
      formData.append("last_name", data.last_name); // Corrected key
      formData.append("email", data.email);
      formData.append("phone_1", data.phone1); // Corrected key
      formData.append("phone_2", data.phone2); // Corrected key
      formData.append("head_shot", selectedFile);

      const response = await axios.post(`${updatePersonal}`, formData, {
        headers: {
          'Authorization': `Bearer ${storedToken}`,
          'Content-Type': 'multipart/form-data', // Set content type for file upload
        },
      });//for api call
       if(response.data){
      notify("Your Profile is updated successfully");
       }
    } catch (error) {
      console.error(error);
      notify2("Something went wrong!");
    } finally {
      setLoading(false);
    }
  };
console.log(Image)
  return (
    <>
      {loading && (
        <div className="loading-container">
          <div className="overlay">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-ring-loading"
              colors={["#61b33b", "#61b33b", "#61b33b", "#61b33b", "#61b33b"]}
            />
          </div>
        </div>
      )}
      <ToastContainer />
      <div className="wrapper h-100 py-4 wrapper-top-margin">
        <div className="container">
          {/* <!--Wrapper body--> */}
          <section className="wrapper-body h-100">
            <div className="container-fluid h-100">
              <div className="row h-100">
                <div className="col-md-12 h-100">
                  <div className="main-content h-100">
                    <div className="row h-100 justify-content-center">
                      {/* <!-- <div className="col-md-4 account-left-bg h-100 d-none d-xl-block">
                                <div className="account-left h-100">
                                     <div className="account-visual">
                                        <img src="images/my-card.png" alt="Digital Card" className="img-fluid">
                                    </div> 
                                </div>
                            </div> --> */}
                      <div className="col-xl-12 h-100">
                        <div className="dashborad h-100">
                          <div className="row justify-content-center">
                            <div className="col-10">
                              {" "}
                              <div className="details">
                                <div className="details-header">
                                  <h3>Personal Information</h3>

                                  <div className="details-form pt-3">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                      <div class="small-12 medium-2 large-2 columns">
                                        <div class="circle mb-3">
                                         {Image && <img
                                            className="profile-pic"
                                            src={imageSrc ||Image  }
                                          />}
                                          
                                        </div>
                                        <div class="p-image">
                                          <label
                                            htmlFor="update"
                                            className="fa fa-camera upload-button"
                                          ></label>
                                          <input
                                            className="file-upload"
                                            type="file"
                                            id="update"
                                            onChange={(e) => {
                                              handleFileChange(e);
                                              register("update");
                                            }}
                                          />
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="mb-4 cst-input user">
                                          <input
  type="text"
  id="first_name"
  {...register("first_name",{
   
    pattern: /^[A-Za-z\s]+$/,
  })} 
  className="form-control email-input"
  placeholder="First Name"
  name="first_name"
/>
{
 errors.first_name && errors.first_name.type === "pattern" && (
    <span className="text-danger">
      Name must contain only letters
    </span>
  )}
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="mb-4 cst-input user">
                                            <input
                                              type="text"
                                              id="last_name"
                                              {...register("last_name",{
                                               
                                                pattern: /^[A-Za-z\s]+$/,
                                              })}
                                              className="form-control email-input"
                                              placeholder="Last Name"
                                              name="last_name"
                                            />
                                            {
 errors.last_name && errors.last_name.type === "pattern" && (
    <span className="text-danger">
      Name must contain only letters
    </span>
  )}
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="mb-4 cst-input">
                                            <input
                                              type="email"
                                              id="email"
                                             
                                              {...register("email")}
                                              className="form-control email-input" style={{backgroundColor: '#D4D4D4'}}
                                              placeholder="Email"
                                              readOnly
                                            />
                                          </div>
                                        </div>
                                        
                                      </div>
                                    </form>
                                  </div>
                                </div>
                                <div className="digital-card-action">
                                  <button
                                    type="submit"
                                    className="btn primary-btn"
                                    onClick={handleSubmit(onSubmit)}
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Personal;
