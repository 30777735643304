import React, { useState } from "react";
import Button from "./Button";
import { Link } from "react-router-dom";
import arrowBack from "../images/arrow-back.svg";
import logo from "../images/logo.svg";
const Profile = ({ handleSubmit, handleNextSection, register,handleSkipSection, onSubmit,email , errors, watch}) => {
  const [focused, setFocused] = useState(false);
 
  const handleFocus = () => {
    setFocused(true);
  };
  
  const handleBlur = () => {
    setFocused(false);
  };
  return (
    
    <div className="container">
      <section className="wrapper-body">
        <div className="container-fluid h-100">
          <div className="row h-100">
            <div className="col-md-12 h-100">
              <div className="main-content h-100">
                <div className="row h-100 flex-row-reverse">
                  {/* <div className="col-md-4 account-left-bg h-100">
                  <div className="account-left h-100">
                    {/* <!-- <div className="account-visual">
                                    <img src="images/my-card.png" alt="Digital Card" className="img-fluid">
                                </div> --> 
                  </div>
                </div> */}
                  <div className="col-xl-12 h-100">
                    <div className="digital-card-form h-100">
                      <div className="digital-card-inputs">
                        <div className="site-logo">
                        <Link to="/complete-profile">
                            <img src={logo} alt="Logo" />
                          </Link>
                        </div>
                        <div className="details">
                          <div className="details-header">
                            <h3>Fill in your Profile Details</h3>
                            <p>Please fill in your details to continue. </p>
                            <Link
                              onClick={handleSubmit(() =>
                                handleSkipSection(false)
                              )}
                            >
                              Skip
                            </Link>
                          </div>
                          <div className="details-form pt-3">
                            <form onSubmit={handleSubmit(onSubmit)}>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="mb-4 cst-input user"style={{ position: 'relative' }}>
                                  
                                    <input
                                      type="text"
                                      id="first_name"
                                      {...register("first_name",{
   
                                        pattern: /^[A-Za-z\s]+$/,
                                      })} 
                                      className="form-control email-input"
                                      placeholder="First Name"
                                      name="first_name"
                                      onFocus={handleFocus}
                                      onBlur={handleBlur}
                                    />
                                      {!focused && !watch('first_name') && (
        <span style={{ position: 'absolute', top: '40%', left : '125px', transform: 'translateY(-50%)', color: 'red' }}>*</span>
      )}
      { errors.first_name && errors.first_name.type === "pattern" && (
    <span className="text-danger">
      Name must contain only letters
    </span>
  )}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-4 cst-input user">
                                  <input
                                      type="text"
                                      id="last_name"
                                      {...register("last_name",{
                                               
                                        pattern: /^[A-Za-z\s]+$/,
                                      })}
                                      className="form-control email-input"
                                      placeholder="Last Name"
                                    />
                                     {
 errors.last_name && errors.last_name.type === "pattern" && (
    <span className="text-danger">
      Name must contain only letters
    </span>
  )}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-4 cst-input">
                                   
                                    <input
                                      type="email"
                                      id="email"
                                      value={email}
                                      className="form-control email-input"style={{backgroundColor: '#D4D4D4'}}
                                      placeholder="Email"
                                      readOnly
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-4 cst-input phone">
  
                                     <input
                                      type="text"
                                      id="phone1"
                                      {...register("phone_1", {
                                            
                                        pattern: {
                                          value: /^\d+$/, 
                                          message: "Please enter a valid phone number",
                                        },
                                      })}
                                      name="phone_1"
                                      className="form-control email-input"
                                      placeholder="Phone1"
                                    /> 
                                                                             {errors.phone_1 && (
  <span className="text-danger">{errors.phone_1.message}</span>
)}
    
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="mb-4 cst-input phone">
                                    
                                    <input
                                      type="text"
                                      id="phone2"
                                      {...register("phone_2", {
                                        pattern: {
                                          value: /^\d*$/, // Match any number of digits (including empty)
                                          message: "Please enter a valid phone number",
                                        },
                                      })}
                                      name="phone_2"
                                      className="form-control email-input"
                                      placeholder="Phone2"
                                    />
                                                                                {errors.phone_2 && (
  <span className="text-danger">{errors.phone_2.message}</span>
)}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-4 cst-input degination">
                                    <input
                                      type="text"
                                      id="job_role_title"
                                      {...register("job_role_title", { 
                                        
                                        pattern: {
                                          value: /^[A-Za-z\s]+$/,
                                          message: "Only letters allowed"
                                        }
                                      })}
                                      className="form-control email-input"
                                      placeholder="Job Role Title"
                                    />
                                     {errors.job_role_title && <p className="text-danger">{errors.job_role_title.message}</p>}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-4 cst-input website">
                                  <input
                                      type="url"
                                      id="website"
                                      {...register("website",{ 
                                     
                                        pattern: {
                                          value: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i,
                                          message: "Invalid website URL"
                                        }})}
                                      className="form-control email-input"
                                      placeholder="Website"
                                    /> 
                                      {errors.website && <p className="text-danger">{errors.website.message}</p>}
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="digital-card-action">
                          <button
                            onClick={() => handleNextSection(true)}
                            className="btn primary-btn"
                          >
                            <img src={arrowBack} alt="arrow-left" /> Back
                          </button>
                          <Button
                            onClick={handleSubmit(() =>
                              handleNextSection(false)
                            )}
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
   
  );
};

export default Profile;
