import React, { useEffect, useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Country } from "country-state-city";
import { useNavigate } from "react-router-dom";
import { ColorRing } from "react-loader-spinner";
import {callApi,handleFormSubmit} from "./api";
import HeadShot from "./Head_shot";
import CompanyLogo from "./Company_logo";
import Profile from "./Profile";
import CompanyInfo from "./CompanyInfo";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const ContactForm = ({officalUrl}) => {
  const [currentSection, setCurrentSection] = useState("head_shot");
  const { register, handleSubmit, watch,formState: { errors }, } = useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [imageSrc, setImageSrc] = useState(null);
  const [originalFileName, setOriginalFileName] = useState(null);
  const [imageSrc2, setImageSrc2] = useState(null);
  const [originalFileName2, setOriginalFileName2] = useState(null);
  const [businessCardId, setBusinessCardId] = useState(null);
  const [email,setEmail]=useState();
  
  useLayoutEffect(() => {
    const fetchData = async () => {
      try {
        await checkUserQR(); // Wait for checkUserQR to complete before proceeding
        setEmail(localStorage.getItem("email"));
  
        // Simulate loading for 1 second
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchData(); // Call the fetchData function
  
  }, []);
  
 
  async function checkUserQR() {
    try {
      const data = await axios.get(officalUrl, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        }
      });
  
      if (data.data.data.id) {
        navigate("/dashboard");
      }
    } catch (error) {
      console.error(error);
   
    }
  }
  
 
  


  const notify = (data) =>
    toast.error(data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  

  
  const constructFormData = (section, data, business_card_id) => {
    const formData = new FormData(); // Fix: Correct casing for "FormData"

    switch (section) {
      case "head_shot":
        formData.append("current_step", section);
        formData.append("head_shot", originalFileName);
        break;

      case "company_logo":
        formData.append("current_step", section);
        formData.append("company_logo", originalFileName2);
        formData.append("business_card_id", business_card_id);
        break;

      case "profile_details":
        formData.append("current_step", section);
        formData.append("first_name", data.first_name);
        formData.append("last_name", data.last_name);
        formData.append("job_role_title", data.job_role_title);
        formData.append("email", email);
        formData.append("phone_1", data.phone_1);
        formData.append("phone_2", data.phone_2);
        formData.append("website", data.website);
        formData.append("business_card_id", business_card_id);
        break;

      case "company_details":
        formData.append("current_step", section);
        formData.append("company_name", data.company_name);
        formData.append("company_tagline", data.company_tagline);
        formData.append("address_line_1", data.address_line_1);
        formData.append("address_line_2", data.address_line_2);
        formData.append("city", data.city);
        formData.append("state", data.state);
        formData.append("postal_code", data.postal_code);
        formData.append("country", data.country);
        formData.append("personal_social_linkedin", data.personal_social_linkedin);
        formData.append("personal_social_facebook", data.personal_social_facebook);
        formData.append("personal_social_twitter", data.personal_social_twitter);
        formData.append("personal_social_instagram", data.personal_social_instagram);
        formData.append("business_card_id", business_card_id);
        break;

      default:
        break;
    }

    return formData;
  };

  

  const handleNextSection = async (isBackButton) => {
    
    try {
      if (!isBackButton ) {
        const isValid = validateMandatoryFields(); // Implement this function to validate mandatory fields
      
        if (!isValid) {
          // If mandatory fields are not valid, return without proceeding
          return;
        }
        const formData = constructFormData(currentSection, watch(),businessCardId);
        // Call the API function
        setLoading(true);
        
       const response=await callApi(formData);
 
      
      
       if (response.data.business_card_id) {
        setBusinessCardId( response.data.business_card_id);
      }
      if (response.data.qrCodePath) {
        localStorage.setItem("image", response.data.qrCodePath);
      
      }
      if(response.data.businessCardDetails){
   
        localStorage.setItem("firstname",response.data.businessCardDetails.first_name)
        localStorage.setItem("Secondname",response.data.businessCardDetails.last_name)
        localStorage.setItem("id",response.data.businessCardDetails.id)

      }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }

    if (isBackButton) {
      
      if (currentSection === "company_logo") {
        setCurrentSection("head_shot");
      } else if (currentSection === "profile_details") {
        setCurrentSection("company_logo");
      } else if (currentSection === "company_details") {
        setCurrentSection("profile_details");
      }
    } else {
      if (currentSection === "head_shot") {
        setCurrentSection("company_logo");
      } else if (currentSection === "company_logo") {
        setCurrentSection("profile_details");
      } else if (currentSection === "profile_details") {
        setCurrentSection("company_details");
      }
    }
  };


  const validateMandatoryFields = () => {
    let isValid = true;
  
    
    switch (currentSection) {
      case "head_shot":
      
        if (!originalFileName) {
          isValid = false;
          notify("Please upload your head shot or skip this");
        }
        break;
  
      case "company_logo":
        // Example: Check if the company logo image is uploaded
        if (!originalFileName2) {
          isValid = false;
          notify("Please upload your company logo or skip this.");
        }
        break;
  
      case "profile_details":
        if (!watch("first_name")) {
          isValid = false;
          notify("Please enter your first name or skip this.");
        }
        break;
  
      case "company_details":
        if (!watch("company_name")) {
          isValid = false;
          notify("Please enter your company name or skip this.");
        }
        break;
  
      default:
        break;
    }
  
    return isValid;
  };
  


  const handleSkipSection =  async (isBackButton) => {
    
    try {
      if (!isBackButton) {
        const formData = constructFormData(currentSection, watch(),businessCardId);
        // Call the API function
        setLoading(true);
       const response=await callApi(formData);
       if (currentSection === "company_details") {
         
        navigate("/qrCode");
        return;
      }
      
      
       if (response.data.business_card_id) {
        setBusinessCardId( response.data.business_card_id);
      }
      if (response.data.qrCodePath) {
        localStorage.setItem("image", response.data.qrCodePath);
      
      }
      if(response.data.businessCardDetails){
   
        localStorage.setItem("firstname",response.data.businessCardDetails.first_name)
        localStorage.setItem("Secondname",response.data.businessCardDetails.last_name)
        localStorage.setItem("id",response.data.businessCardDetails.id)

      }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }

    if (isBackButton) {
    
      if (currentSection === "company_logo") {
        setCurrentSection("head_shot");
      } else if (currentSection === "profile_details") {
        setCurrentSection("company_logo");
      } else if (currentSection === "company_details") {
        setCurrentSection("profile_details");
      }
    } else {
      if (currentSection === "head_shot") {
        setCurrentSection("company_logo");
      } else if (currentSection === "company_logo") {
        setCurrentSection("profile_details");
      } else if (currentSection === "profile_details") {
        setCurrentSection("company_details");
      }
    }
  };

  const countries = Country.getAllCountries();

  const countryOptions = countries.map((country) => (
    <option key={country.isoCode} value={country.name} >
      {country.name}
    </option>
  ));

  const onSubmit = async () => {
    try {
      const isValid = validateMandatoryFields(); // Implement this function to validate mandatory fields
      
      if (!isValid) {
        // If mandatory fields are not valid, return without proceeding
        return;
      }
      if (currentSection === "company_details") {
        // If currently on the CompanyInfo section, navigate to qrCode directly
        navigate("/qrCode");
      } 
      const formData = constructFormData(currentSection, watch(),businessCardId);
       setLoading(true);
     await handleFormSubmit(formData);
     
     

      navigate("/qrCode");
    } catch (error) {
      console.error(error);
      
    }finally{
      setLoading(false);
    }

  };

  return (
    <>
      {loading && (
        <div className="loading-container">
          <div className="overlay">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-ring-loading"
              colors={["#61b33b", "#61b33b", "#61b33b", "#61b33b", "#61b33b"]}
            />
          </div>
        </div>
      )}
      <ToastContainer/>
      <div className="wrapper">
      {currentSection === "head_shot" && (
        <HeadShot
          handleSubmit={handleSubmit}
          handleNextSection={handleNextSection}
          register={register}
          onSubmit={onSubmit}
          imageSrc={imageSrc}
          setImageSrc={setImageSrc}
          setOriginalFileName={setOriginalFileName}
          handleSkipSection ={handleSkipSection}
        />
      )}
      {currentSection === "company_logo" && (
        <CompanyLogo
          handleSubmit={handleSubmit}
          handleNextSection={handleNextSection}
          register={register}
          onSubmit={onSubmit}
          imageSrc2={imageSrc2}
          setImageSrc2={setImageSrc2}
          setOriginalFileName2={setOriginalFileName2}
          handleSkipSection ={handleSkipSection}
        />
      )}

      {currentSection === "profile_details" && (
        <Profile
          handleSubmit={handleSubmit}
          handleNextSection={handleNextSection}
          register={register}
          onSubmit={onSubmit}
          email={email}
          handleSkipSection ={handleSkipSection}
          watch={watch}
          errors={errors}
        />
      )}
      {currentSection === "company_details" && (
        <CompanyInfo  handleSubmit={handleSubmit}
        handleNextSection={handleNextSection}
        register={register}
        onSubmit={onSubmit}
        handleSkipSection ={handleSkipSection}
        countryOptions={countryOptions}
        watch={watch}
        errors={errors}
        />
        
      )}
      </div>
    </>
    
  );
};

export default ContactForm;
