import React, { useEffect, useState } from "react";
import young from "../images/default-user-img.webp";
import axios from "axios";
import { ColorRing } from "react-loader-spinner";
const ScanQr = ({officalUrl}) => {
  const [qrData, setQrData] = useState(null);
  const [firstName,setFirstName]=useState();
  const[lastName,setLastName]=useState();
  const [headShot,setHeadShort]=useState();
  const image='https://mydigitalcard-admin.kitlabs.us'
  const [loading, setLoading] = useState();
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        setLoading(true);
        const storedToken = localStorage.getItem('token');
        const response = await axios.get(officalUrl, {
          headers: {
            'Authorization': `Bearer ${storedToken}`,
            "Content-Type": "application/json"
         
          }
        });
        setQrData(`${image}${response.data.data.qr_code}`);
        setFirstName(response.data.data.first_name);
        setLastName(response.data.data.last_name);
        setHeadShort(response.data.data.head_shot ? `${image}${response.data.data.head_shot}` : young);

       setLoading (false)

      } catch (error) {
        console.error("Error fetching QR data:", error);
      }
    };

    fetchData();
  }, [officalUrl]);
  console.log("dataqrcode",qrData);
  return (<>
    { loading && (
    <div className="loading-container">
      <div className="overlay">
        <ColorRing
          visible={true}
          height="80"
          width="80"
          ariaLabel="color-ring-loading"
          colors={["#61b33b", "#61b33b", "#61b33b", "#61b33b", "#61b33b"]}
        />
      </div>
    </div>
  )}
    <div className="wrapper h-100 py-4 wrapper-top-margin">
      <div className="container">
        {/* <!--Wrapper body--> */}
        <section className="wrapper-body h-100 pb-4">
          <div className="container-fluid h-100">
            <div className="row h-100">
              <div className="col-md-12 h-100">
                <div className="main-content h-100">
                  <div className="row h-100 justify-content-center">
                    {/* <!-- <div className="col-md-4 account-left-bg h-100 d-none d-xl-block">
                                <div className="account-left h-100">
                                     <div className="account-visual">
                                        <img src="images/my-card.png" alt="Digital Card" className="img-fluid">
                                    </div> 
                                </div>
                            </div> --> */}
                    <div className="col-xl-12 h-100">
                      <div className="dashborad h-100">
                        <div className="row justify-content-center">
                          <div className="col-12 col-md-8">
                            {" "}
                            <div className="details">
                              <div className="details-header d-flex justify-content-between align-items-center text-center">
                                <div className="user-name">
                                  <p className="d-flex align-items-center mb-0 justify-content-center">
                                    Hello!{" "}
                                  </p>
                                  <h3>{firstName}</h3>
                                </div>
                                <div className="user-pic mb-3">
                                  <span>
                                    <img src={headShot|| young} />
                                  </span>
                                </div>
                              </div>
                              <div className="my-card">
                                <div className="my-card-visual">
                                  <img src={qrData} alt="" />
                                  <br />
                                  <span>Point your camera to QR code</span>
                                </div>
                                <div className="my-card-body">
                                  <h4>{firstName} {lastName} </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    </> 
  );
};

export default ScanQr;
