import React from 'react'
import arrowNext from "../images/arrow-next.svg"
const Button = ({ onClick, children }) => {
     
    return (
      <button onClick={onClick} className="ms-auto btn primary-btn">
        {children} <img src={arrowNext} alt="arrow"/>
      </button>
    );
  };

export default Button
