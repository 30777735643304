import React from "react";
import image1 from "../images/my-card.png"
const SideContent = () => {
  return (
    <>
      <div className="col-md-6 account-left-bg h-100 d-none d-xl-block">
        <div className="account-left h-100">
          <div className="account-visual h-100">
            <img src={image1} alt="Digital Card" className="img-fluid" />
          </div>
        </div>
      </div>
    </>
  );
};

export default SideContent;
