import React from "react";

import { Link } from "react-router-dom";
import news from "../images/News.jpg";

const News = () => {
  return (
    <div className="wrapper h-auto py-4 wrapper-top-margin">
      <div className="container">
        {/* <!--Wrapper body--> */}
        <section className="wrapper-body h-100 pb-4">
          <div className="container-fluid h-100">
            <div className="row h-100">
              <div className="col-md-12 h-100">
                <div className="main-content h-100">
                  <div className="row h-100 justify-content-center">
                    {/* <!-- <div className="col-md-4 account-left-bg h-100 d-none d-xl-block">
                              <div className="account-left h-100">
                                   <div className="account-visual">
                                      <img src="images/my-card.png" alt="Digital Card" className="img-fluid">
                                  </div> 
                              </div>
                          </div> --> */}
                    <div className="col-xl-12 h-100">
                      <div className="dashborad h-100">
                        <div className="row justify-content-center">
                          <div className="col-12 col-md-10">
                            {" "}
                            <div className="details">
                              
                                <h3>News</h3>


                                  <div className="row">
                                    <div className="col-md-6 col-xl-4">
                                      <div
                                        className="card mb-4 "
                                        
                                      >
                                        <img
                                          src={news}
                                          className="card-img-top"
                                          alt="..."
                                        />
                                        <div className="card-body">
                                         <Link to="/newslist" className="title-color"><h5 className="card-title">
                                            How to choose the right laptop.
                                          </h5></Link> 
                                          <p className="card-text">
                                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Suscipit autem corrupti saepe porro, velit nesciunt atque id ex corporis veritatis eius ea, fugiat hic totam deserunt neque quo accusantium nostrum?
                                          </p>
                                          
                                        </div>
                                      </div>
                                    
                                    </div>

                                    <div className="col-md-6 col-xl-4">
                                      <div
                                        className="card mb-4 "
                                        
                                      >
                                        <img
                                          src={news}
                                          className="card-img-top"
                                          alt="..."
                                        />
                                        <div className="card-body">
                                        <Link to="/newslist" className="title-color"><h5 className="card-title">
                                            How to choose the right laptop.
                                          </h5></Link> 
                                          <p className="card-text">
                                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Suscipit autem corrupti saepe porro, velit nesciunt atque id ex corporis veritatis eius ea, fugiat hic totam deserunt neque quo accusantium nostrum?
                                          </p>
                                        
                                        </div>
                                      </div>
                                    
                                    </div>
                                    <div className="col-md-6 col-xl-4">
                                      <div
                                        className="card mb-4 "
                                        
                                      >
                                        <img
                                          src={news}
                                          className="card-img-top"
                                          alt="..."
                                        />
                                        <div className="card-body">
                                        <Link to="/newslist" className="title-color"><h5 className="card-title">
                                            How to choose the right laptop.
                                          </h5></Link> 
                                          <p className="card-text">
                                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Suscipit autem corrupti saepe porro, velit nesciunt atque id ex corporis veritatis eius ea, fugiat hic totam deserunt neque quo accusantium nostrum?
                                          </p>
                                          
                                        </div>
                                      </div>
                                    
                                    </div>
                                    <div className="col-md-6 col-xl-4">
                                      <div
                                        className="card mb-4 "
                                        
                                      >
                                        <img
                                          src={news}
                                          className="card-img-top"
                                          alt="..."
                                        />
                                        <div className="card-body">
                                        <Link to="/newslist" className="title-color"><h5 className="card-title">
                                            How to choose the right laptop.
                                          </h5></Link> 
                                          <p className="card-text">
                                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Suscipit autem corrupti saepe porro, velit nesciunt atque id ex corporis veritatis eius ea, fugiat hic totam deserunt neque quo accusantium nostrum?
                                          </p>
                                         
                                        </div>
                                      </div>
                                    
                                    </div>
                                    <div className="col-md-6 col-xl-4">
                                      <div
                                        className="card mb-4 "
                                        
                                      >
                                        <img
                                          src={news}
                                          className="card-img-top"
                                          alt="..."
                                        />
                                        <div className="card-body">
                                        <Link to="/newslist" className="title-color"><h5 className="card-title">
                                            How to choose the right laptop.
                                          </h5></Link> 
                                          <p className="card-text">
                                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Suscipit autem corrupti saepe porro, velit nesciunt atque id ex corporis veritatis eius ea, fugiat hic totam deserunt neque quo accusantium nostrum?
                                          </p>
                                          
                                        </div>
                                      </div>
                                    
                                    </div>
                                    <div className="col-md-6 col-xl-4">
                                      <div
                                        className="card mb-4 "
                                        
                                      >
                                        <img
                                          src={news}
                                          className="card-img-top"
                                          alt="..."
                                        />
                                        <div className="card-body">
                                        <Link to="/newslist" className="title-color"><h5 className="card-title">
                                            How to choose the right laptop.
                                          </h5></Link> 
                                          <p className="card-text">
                                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Suscipit autem corrupti saepe porro, velit nesciunt atque id ex corporis veritatis eius ea, fugiat hic totam deserunt neque quo accusantium nostrum?
                                          </p>
                                          
                                        </div>
                                      </div>
                                    
                                    </div>
                                  </div>
                                 
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default News;
