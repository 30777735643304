import React, { useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import eye from "../images/eye.svg";
import image1 from "../images/logo.svg";
import axios from "axios";
import { ColorRing } from "react-loader-spinner";
import SideContent from "./SideContent";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import eyeclose from "../images/eyeclose.svg";

const Registration = ({ parent, registerUrl,contacts,detailUrl}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
 
  const [passwordError, setPasswordError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [passwordVisible, setPasswordVisible] = useState({
    newPassword: false,
    confirmPassword: false,
  });
  const id = getParameterByName("id");
  console.log("datatrry",id)
  const [hasToken, setHasToken] = useState(false);
  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  const handleVisibilityToggle = (field) => {
    setPasswordVisible((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const navigate = useNavigate();

  useLayoutEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const validatePassword = (value) => {
    const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/;
    return regex.test(value);
  };

  const onSubmit = async (data) => {
    if (data.password !== data.pwd) {
      setPasswordError(true);
      return;
    }

    if (!validatePassword(data.password)) {
      setPasswordError(true);
      return;
    }

    const notify = () =>
      toast.error("This email is already Registered please sign in", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

    try {
      setLoading(true);
       
      const response = await axios.post(registerUrl, {
        email: data.email,
        password: data.password,
        register_type: "web",
        device_type: "web",
        role_type: 2,
      });
      parent( response.data.data.token);
       localStorage.setItem("email",data.email)
     
      if(id && response.data.message==="Registered Successfully"){
             await axios.post(contacts,{
             
              linked_business_card_id: id,
             },{headers: {
              'Authorization': `Bearer ${response.data.data.token}`
             }})

             const response2 = await axios.get(`${detailUrl}?id=${id}`);
             const cardDetails = response2.data.data;
       
             const generateVCardData = (first_name, last_name, phone_1, email) => {
               return `BEGIN:VCARD\r\nVERSION:3.0\r\nFN:${first_name} ${last_name}\r\nTEL:${phone_1}\r\nEMAIL:${email}\r\nEND:VCARD`;
             };
       
             const vcardData = generateVCardData(
               cardDetails.first_name,
               cardDetails.last_name,
               cardDetails.phone_1,
               cardDetails.email
             );
       
             const downloadVCard = (vcardData) => {
               const blob = new Blob([vcardData], { type: "text/vcard" });
               const url = URL.createObjectURL(blob);
               const link = document.createElement("a");
               link.href = url;
               link.setAttribute("download", "contact.vcf");
               link.click();
             };
             
             downloadVCard(vcardData);
          navigate(`/welcome`);
      }
     else{
      navigate("/welcome");
      }
      
     
    } catch (error) {
      notify();
      console.error(error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  const handlePasswordChange = (e) => {
    if (
      passwordError &&
      (e.target.id === "password" || e.target.id === "pwd")
    ) {
      setPasswordError(false);
    }
  };
  return (
    <>
      {loading && (
        <div className="loading-container">
          <div className="overlay">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-ring-loading"
              colors={["#61b33b", "#61b33b", "#61b33b", "#61b33b", "#61b33b"]}
            />
          </div>
        </div>
      )}
      <ToastContainer />
      <div className="wrapper">
        <section className="wrapper-body">
          <div className="container-fluid h-100">
            <div className="row h-100">
              <div className="col-md-12 h-100">
                <div className="main-content h-100">
                  <div className="row h-100">
                    <SideContent />
                    <div className="col-xl-6 h-100">
                      <div className="account-right h-100">
                        <div className="site-logo text-center">
                          <a href="sign-in.html">
                            <img src={image1} alt="Logo" />
                          </a>
                        </div>
                        <div className="account-form">
                          <div className="account-form-header">
                            <h3>Sign Up</h3>
                            <p>
                              Please fill in your details to continue sign up.
                            </p>
                          </div>
                          <div className="account-inputs">
                            <form onSubmit={handleSubmit(onSubmit)}>
                              <div className="mb-3 mt-3 cst-input">
                              <label htmlFor="email">Email <span style={{ color: 'red' }}>*</span></label>
                                <input
                                  type="email"
                                  className="form-control email-input"
                                  id="email"
                                  {...register("email", { required: true })}
                                  placeholder="Enter email"
                                />

                                {errors.email && (
                                  <span className="error-message">
                                    This field is required
                                  </span>
                                )}
                              </div>
                              <div className="mb-3 cst-input password-eye">
                              <label htmlFor="password">Password <span style={{ color: 'red' }}>*</span></label>
                                <span
                                  className={`eye-icon ${
                                    passwordVisible.newPassword ? "visible" : ""
                                  }`}
                                  onClick={() =>
                                    handleVisibilityToggle("newPassword")
                                  }
                                >
                                                                 {passwordVisible.newPassword ? (
    <img src={eyeclose} alt="close-eye-icon" />
  ) : (
    <img src={eye} alt="eye-icon" />
  )}
                                </span>
                                <input
                                  type={
                                    passwordVisible.newPassword
                                      ? "text"
                                      : "password"
                                  }
                                  id="password"
                                  {...register("password", {
                                    required: true,
                                    minLength: 7,
                                    pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/,
                                  })}
                                  onChange={handlePasswordChange}
                                  placeholder="Enter password"
                                  className="form-control pass-input"
                                />
                                {errors.password &&
                                  errors.password.type === "required" && (
                                    <span className="error-message">
                                      This field is required
                                    </span>
                                  )}
                                {errors.password &&
                                  errors.password.type === "minLength" && (
                                    <span className="error-message">
                                      Your password must be more than 7
                                      characters long.
                                    </span>
                                  )}
                                {errors.password &&
                                  errors.password.type === "pattern" && (
                                    <span className="error-message">
                                      Your password must contain at least 1
                                      Uppercase, 1 Lowercase, 1 Numeric, and 1
                                      special character.
                                    </span>
                                  )}
                              </div>
                              <div className="mb-5 cst-input password-eye">
                              <label htmlFor="password">Confirm Password <span style={{ color: 'red' }}>*</span></label>
                                <span
                                  className={`eye-icon ${
                                    passwordVisible.confirmPassword
                                      ? "visible"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    handleVisibilityToggle("confirmPassword")
                                  }
                                >
                                    {passwordVisible.confirmPassword ? (
    <img src={eyeclose} alt="close-eye-icon" />
  ) : (
    <img src={eye} alt="eye-icon" />
  )}
                                </span>
                                <input
                                  type={
                                    passwordVisible.confirmPassword
                                      ? "text"
                                      : "password"
                                  }
                                  id="pwd"
                                  {...register("pwd", { required: true })}
                                  onChange={handlePasswordChange}
                                  className="form-control pass-input"
                                  placeholder="Confirm password"
                                />
                                {errors.pwd && (
                                  <span className="error-message">
                                    This field is required
                                  </span>
                                )}
                                {passwordError && (
                                  <span className="error-message">
                                    Passwords do not match
                                  </span>
                                )}
                              </div>
                              <div className="register mb-3">
                                <button
                                  type="submit"
                                  className="btn primary-btn w-100"
                                >
                                  Sign Up
                                </button>
                                <br />
                              </div>
                              <div className=" text-center">
                                <span>or</span>
                              </div>
                              <div className="register text-center">
                                <span>
                                  {" "}
                                  Already have an account?{" "}
                                  {hasToken ? (
          <Link to="/">Sign In</Link>
        ) : (
          <Link to={`/?id=${id}`}>Sign In</Link>
        )}
                                </span>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Registration;
