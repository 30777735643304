import React, { useLayoutEffect, useState } from "react";

import image2 from "../images/logo.svg";
import { Link} from "react-router-dom";
import axios from "axios";
import { useForm } from "react-hook-form";
import { ColorRing } from "react-loader-spinner";
import SideContent from "./SideContent";
import EmailMessage from "./EmailMessage";
const Email = ({resetPassword}) => {

  const [show,setShow]=useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(true);
  useLayoutEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      console.log(data);
     
      const response = await axios.post(resetPassword, data);
      console.log(response.data);
   
      setShow(true);
    } catch (error) {
      // Handle any errors
      console.error(error.message);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  return (
    <>
      {loading && (
        <div className="loading-container">
          <div className="overlay">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-ring-loading"
              colors={["#61b33b", "#61b33b", "#61b33b", "#61b33b", "#61b33b"]}
            />
          </div>
        </div>
      )}
      {show ? <EmailMessage/>:<section className="wrapper-body">
        <div className="container-fluid h-100">
          <div className="row h-100">
            <div className="col-md-12 h-100">
              <div className="main-content h-100">
                <div className="row h-100">
                  <SideContent />
                  <div className="col-xl-6 h-100">
                    <div className="account-right h-100">
                      <div className="site-logo text-center">
                        <Link to="/">
                          <img src={image2} alt="Logo" />
                        </Link>
                      </div>
                      <div className="account-form">
                        <div className="account-form-header">
                          <h3>Forgot Password</h3>
                          <p>Please fill in your details to reset password.</p>
                        </div>
                        <div className="account-inputs">
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-5 mt-3 cst-input">
                            <label htmlFor="email">Email <span style={{ color: 'red' }}>*</span></label>
                              <input
                                type="email"
                                id="email"
                                {...register("email", { required: true })}
                                className="form-control email-input"
                                placeholder="Enter email"
                              />
                              {errors.email && (
                                <span className="error-message">
                                  This field is required
                                </span>
                              )}
                            </div>
                            <div className="register mb-3">
                              <button
                                type="submit"
                                className="btn primary-btn w-100"
                              >
                                Send
                              </button>
                              <br />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>}
      
    </>
  );
};

export default Email;
