import React,{useEffect} from 'react'
import young from "../images/default-user-img.webp";
import MobileDetect from "mobile-detect";
import logo from "../images/logo.svg";
import logo2 from "../images/card-white-logo.svg";
import companyLogo from"../images/company-logo.png";
import envelope from "../images/envelope-green.svg";
import globe from "../images/globe-green.svg";
import phone from "../images/phone-green.svg";
import facebook from "../images/facebook-green.svg"
import linkedin from "../images/linkedin-green.svg";
import twitter from "../images/twitter-green.svg";
import instagram from "../images/instagram-green.svg";
import { Link  } from "react-router-dom";
import axios from "axios";
import { ColorRing } from "react-loader-spinner";
import { useState } from "react";
const MyDigitalCard = ({officalUrl}) => {
  const [loading, setLoading] = useState();
    useEffect(()=>{
      setLoading(true);
        detail();
   },[])  
   const [cardDetails,setCardDetails]=useState({});
   const image='https://mydigitalcard-admin.kitlabs.us'
   async function detail() {
    const storedToken = localStorage.getItem('token');
    try {
      setLoading(true);
      const response = await axios.get(officalUrl, {
        headers: {
          'Authorization': `Bearer ${storedToken}`
        }
      });

      console.log(response.data);
      setCardDetails(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error in Axios request:", error);
      setLoading(false);
    }
  }

  const isMobileDevice = () => {
    const md = new MobileDetect(window.navigator.userAgent);
    return md.mobile() !== null || md.tablet() !== null;
  };

  return (<>
   { loading && (
      <div className="loading-container">
        <div className="overlay">
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="color-ring-loading"
            colors={["#61b33b", "#61b33b", "#61b33b", "#61b33b", "#61b33b"]}
          />
        </div>
      </div>
    )}
    <div className="wrapper h-auto py-4 wrapper-top-margin">
    <div className="container h-100 ">
    <section className="wrapper-body h-auto">
    <div className="container-fluid h-100">
        <div className="row h-100">
            <div className="col-md-12 h-100" id="colContainer">
                <div className="main-content h-100">
                    <div className="row h-100 py-3 flex-row-reverse">
                        <div className="col-md-12 h-100">
                            <div className="digital-card-form h-100">
                                <div className="digital-card-inputs">
                                  
                                    <div className="details d-flex align-items-center justify-content-center mt-2">
                            <div className="card-details">
                              <div className="card-detail-logo">
                                <img src={companyLogo} alt="" />
                              </div>
                              <div className="card-detail-visual">
                              <img src={cardDetails.head_shot ? `${image}${cardDetails.head_shot}` : young} alt="" />
                              </div>
                              <div className="card-detail-header">
                                <h3>{cardDetails.first_name} {cardDetails.last_name}</h3>
                                <p>{cardDetails.job_role_title}</p>
                              </div>
                              <div className="card-detail-body">
                                <div className="card-detail-contact">
                                  {cardDetails.email && <Link to=""><span><img src={envelope} alt="" /></span>{cardDetails.email}</Link>}
                                  {cardDetails.website && <Link to=""><span><img src={globe} alt="" /></span>{cardDetails.website}</Link>}
                                  {cardDetails.phone_1 && <Link to=""><span><img src={phone} alt="" /></span>{cardDetails.phone_1}</Link>}
                                </div>
                                <div className="card-detail-social">
                                  {cardDetails.personal_social_facebook &&<Link to=""><span><img src={facebook} alt="" /></span>{cardDetails.personal_social_facebook}</Link>}
                                  {cardDetails.personal_social_linkedin &&<Link to=""><span><img src={linkedin} alt="" /></span>{cardDetails.personal_social_linkedin}</Link>}
                                  {cardDetails.personal_social_twitter &&<Link to=""><span><img src={twitter} alt="" /></span>{cardDetails.personal_social_twitter}</Link>}
                                  {cardDetails.personal_social_instagram &&<Link to=""><span><img src={instagram} alt="" /></span>{cardDetails.personal_social_instagram}</Link>}
                                </div>
                              </div>
                            </div>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</div>
</div>
</>
  );
};



export default MyDigitalCard
