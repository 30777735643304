import React from 'react'

import image2 from "../images/logo.svg"
import { Link } from 'react-router-dom'
import SideContent from './SideContent'

const EmailMessage = () => {
  return (
    <section className="wrapper-body">
    <div className="container-fluid h-100">
        <div className="row h-100">
            <div className="col-md-12 h-100">
                <div className="main-content h-100">
                    <div className="row h-100">
                        <SideContent/>
                        <div className="col-md-6 h-100">
                            <div className="account-right h-100">
                                <div className="site-logo text-center">
                                    <Link to="/"><img src={image2} alt="Logo"/></Link>
                                </div>
                                <div className="account-form">
                                    <div className="account-form-header text-center">
                                        <h2>Congratulations!</h2>
                                        <h5 className="mb-5">An email has been sent to your email ID for password reset.</h5>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default EmailMessage;
