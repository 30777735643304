import React, { useState,useEffect} from 'react'

import { Country } from "country-state-city";
import { useForm } from "react-hook-form";
import young from "../images/default-user-img.webp";
import { ColorRing } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';

const Official = ({officalUrl,updateOffical}) => {
  const {
    register,
    handleSubmit,
setValue,
formState: { errors }, 
  } =useForm();
  const image='https://mydigitalcard-admin.kitlabs.us';
  const [loading, setLoading] = useState();
  const[imageSrc2,setImageSrc2]= useState();
  const [originalFileName2,setOriginalFileName2]=useState();
   const [Image,setImage]=useState();

  useEffect(() => {
    setLoading(true);


  
    const fetchData = async () => {
      const storedToken = localStorage.getItem('token');
      
      try {
       
        const response = await axios.get(`${officalUrl}`, {
          headers: {
            'Authorization': `Bearer ${storedToken}`
          }
        });
        
        
       
        setValue("company_name",response.data.data.company_name);
    setValue("company_tagline", response.data.data.company_tagline);
    setValue("designation", response.data.data.job_role_title);
    setValue("address_line_1", response.data.data.address_line_1);
    setValue("address_line_2", response.data.data.address_line_2);
    setValue("city", response.data.data.city);
    setValue("state", response.data.data.state);
    setValue("phone_1", response.data.data.phone_1);
    setValue("phone_2", response.data.data.phone_2);
    setValue("postal_code", response.data.data.postal_code);
    setValue("country", response.data.data.country);
    setValue("website", response.data.data.website);
    setValue("personal_social_linkedin", response.data.data.personal_social_linkedin);
    setValue("personal_social_facebook", response.data.data.personal_social_facebook);
    setValue("personal_social_twitter", response.data.data.personal_social_twitter);
    setValue("personal_social_instagram", response.data.data.personal_social_instagram);
    setImage(response.data.data.company_logo ? `${image}${response.data.data.company_logo}` : young);

        setLoading(false)
      } catch (error) {
        console.error('Error fetching data:', error);
        
      }
    };

    
    fetchData();
    setTimeout(() => {
      setLoading(false); 
    }, 900); 
  }, [officalUrl,setValue,setImage]); 
  const notify = (message) =>
  toast.success(message, {
    position: "top-right",
    autoClose: 5000,
    fontWeight: "bold",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
const notify2 = (message1) =>
  toast.error(message1, {
    position: "top-right",
    autoClose: 5000,
    fontWeight: "bold",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    console.log(file);

    if (file) {
      const imageUrl = URL.createObjectURL(file);

      setImageSrc2(imageUrl);
    }
    setOriginalFileName2(file);
  };

 

  const countries = Country.getAllCountries();

  const countryOptions = countries.map((country) => (
    <option key={country.isoCode} value={country.name}>
      {country.name}
    </option>
  ));

  const onSubmit = async (data) => {
    const storedToken = localStorage.getItem('token');
    try {
      setLoading(true);
      console.log(data);
      const formData = new FormData();
      formData.append("company_name", data.company_name);
      formData.append("company_tagline", data.company_tagline);
      formData.append("job_role_title", data.designation);
      formData.append("address_line_1", data.address_line_1);
      formData.append("address_line_2", data.address_line_2);
      formData.append("city", data.city);
      formData.append("state", data.state);
      formData.append("phone_1", data.phone_1);
      formData.append("phone_2", data.phone_2);
      formData.append("postal_code", data.postal_code);
      formData.append("country", data.country);
      formData.append("website", data.website);
      formData.append("personal_social_linkedin", data.personal_social_linkedin);
      formData.append("personal_social_facebook", data.personal_social_facebook);
      formData.append("personal_social_twitter", data.personal_social_twitter);
      formData.append("personal_social_instagram", data.personal_social_instagram);
      formData.append("company_logo", originalFileName2);
      
      const response = await axios.post(`${updateOffical}`, formData, {
        headers: {
          'Authorization': `Bearer ${storedToken}`,
          'Content-Type': 'multipart/form-data', 
        },
      });//for api call
      if(response.data){
      notify("Your Offical Information is updated successfully");
       }
    } catch (error) {
      console.error(error);
      notify2("Something went wrong!");
    } finally {
      setLoading(false);
    }
  };
  return (
   <>
{loading && (
        <div className="loading-container">
          <div className="overlay">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-ring-loading"
              colors={["#61b33b", "#61b33b", "#61b33b", "#61b33b", "#61b33b"]}
            />
          </div>
        </div>
      )}
      <ToastContainer />
    <div className="wrapper h-auto py-4 wrapper-top-margin">
      <div className="container">
        {/* <!--Wrapper body--> */}
        <section className="wrapper-body h-auto">
          <div className="container-fluid h-100">
            <div className="row h-100">
              <div className="col-md-12 h-100">
                <div className="main-content h-100">
                  <div className="row h-100 justify-content-center">
                    {/* <!-- <div className="col-md-4 account-left-bg h-100 d-none d-xl-block">
                                <div className="account-left h-100">
                                     <div className="account-visual">
                                        <img src="images/my-card.png" alt="Digital Card" className="img-fluid">
                                    </div> 
                                </div>
                            </div> --> */}
                    <div className="col-xl-12 h-100">
                      <div className="dashborad h-100">
                        <div className="row justify-content-center">
                          <div className="col-10">
                            {" "}
                            <div className="details">
                              <div className="details-header">
                                <h3>Business Information</h3>
                                
                                <div className="details-form pt-3">
                                  <form onSubmit={handleSubmit(onSubmit)}>
                                  <div class="small-12 medium-2 large-2 columns">
                                        <div class="circle mb-3">
                                          <img
                                            className="profile-pic"
                                            src={imageSrc2 ||Image }
                                           
                                          />
                                        </div>
                                        <div class="p-image">
                                          <label
                                            htmlFor="company_logo"
                                            className="fa fa-camera upload-button"
                                          ></label>
                                          <input
                                            className="file-upload"
                                            type="file"
                                            id="company_logo"
                                            onChange={(e) => {
                                              handleImageChange(e);
                                              register("company_logo");
                                            }}
                                          />
                                        </div>
                                      </div>
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="mb-4 cst-input degination">
                                          <input
                                            type="text"
                                            
                                            id="designation"
                                              {...register("designation",{
                                              
                                                pattern: /^[A-Za-z\s]+$/,
                                              })}
                                              name="designation"
                                            className="form-control email-input"
                                            placeholder="Enter your designation"
                                          />
                                          {errors.designation && errors.designation.type === "pattern" && (
  <span className="text-danger">
    Designation must contain only letters
  </span>
)}
                                        </div>
                                      </div>
                                      
                                      
                                      <div className="col-md-6">
                                  <div className="mb-4 cst-input website">
                                  <input
                                      type="url"
                                      id="website"
                                      {...register("website",{ 
                                     
                                        pattern: {
                                          value: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i,
                                          message: "Invalid website URL"
                                        }})}
                                      className="form-control email-input"
                                      placeholder="Enter your Website"
                                    /> 
                                     {errors.website && <p className="text-danger">{errors.website.message}</p>}
                                  </div>
                                  </div>
                                  <div className="col-md-6">
                                <div className="mb-4 cst-input company-name">
                                  <input
                                    type="text"
                                    id="company_name"
                                    {...register("company_name")}
                                    className="form-control email-input"
                                    placeholder="Company Name"
                                  />
                                </div>
                                </div>
                                <div className="col-md-6">
                                <div className="mb-4 cst-input">
                                  
                                    <input
                                    type="text"
                                    id="company_tagline"
                                    {...register("company_tagline")}
                                    className="form-control email-input"
                                    placeholder="Company Tagline"
                                  />
                                </div>
                                </div>
                                <div className="col-md-6">
                                          <div className="mb-4 cst-input phone">
                                            <input
                                              type="text"
                                              id="phone_1"
                                              {...register("phone_1", {
                                            
                                                pattern: {
                                                  value: /^\d+$/, 
                                                  message: "Please enter a valid phone number",
                                                },
                                              })}
                                              name="phone_1"
                                              className="form-control email-input"
                                              placeholder="Enter your phone Number 1"
                                            />
                                            {errors.phone_1 && (
  <span className="text-danger">{errors.phone_1.message}</span>
)}
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="mb-4 cst-input phone">
                                            <input
                                              type="text"
                                              id="phone_2"
                                              {...register("phone_2", {
                                                pattern: {
                                                  value: /^\d*$/, // Match any number of digits (including empty)
                                                  message: "Please enter a valid phone number",
                                                },
                                              })}
                                              name="phone_2"
                                              className="form-control email-input"
                                              placeholder="Enter your phone Number 2"
                                            />
                                            {errors.phone_2 && (
  <span className="text-danger">{errors.phone_2.message}</span>
)}
                                          </div>
                                        </div>
                                <div className="col-md-6">
                                <div className="mb-4 cst-input address ">
                                  {/* <textarea className="form-control" rows="2" id="comment" name="text"></textarea> */}
                                  <textarea
                                    type="text"
                                    className="form-control"
                                    row="2"
                                    id="address_line_1"
                                    {...register("address_line_1")}
                                    placeholder="Address Line 1"
                                  />
                                </div>
                                </div>
                                <div className="col-md-6">
                                <div className="mb-4 cst-input address">
                                <textarea
                                    type="text"
                                    className="form-control"
                                    id="address_line_2"
                                    {...register("address_line_2")}
                                    placeholder="Address Line 2"
                                  />
                                  
                                </div>
                                </div>
                               
                                <div className="col-md-6">
                                <div className="mb-4 cst-input ">
                                <select
                                    className="form-select select-with-image py-2"
                                    id="country"
                                    {...register("country")}
                                    // onChange={handleCountryChange}
                                    
                                  >
                                    <option value="" >Select a country</option>
                                    {countryOptions}
                                  </select>
                                </div>
                                </div>
                                <div className="col-md-6">
                                <div className="mb-4 cst-input state">
                                 
                                   <input
                                    type="text"
                                    id="state"
                                    {...register("state", {
                                      
                                      pattern: {
                                        value: /^[A-Za-z\s]+$/,
                                        message: "Please enter a valid state name",
                                      },
                                    })}
                                    name="state"
                                    className="form-control email-input"
                                    placeholder="State"
                                  />
                                  {errors.state && (
  <span className="text-danger">{errors.state.message}</span>
)}
                                </div>
                                </div>
                                <div className="col-md-6">
                                <div className="mb-4 cst-input state">
                                  
                                <input
                                    type="text"
                                    id="city"
                                    {...register("city", {
                                     
                                      pattern: {
                                        value: /^[A-Za-z]+$/,
                                        message: "Please enter a valid city name",
                                      },
                                    })}
                                    name="city"
                                    className="form-control email-input"
                                    placeholder="City"
                                  />
                                  {errors.city && (
  <span className="text-danger">{errors.city.message}</span>
)}
                                </div>
                              </div>
                              
                                <div className="col-md-6">
                                <div className="mb-4 cst-input state">
                                  <input
                                    type="text"
                                    id="postal_code"
                                    maxLength="6"
                                    {...register("postal_code", {
                                      
                                      pattern: {
                                        value: /^\d{4}(?:\d{2})?\s?$/,

                                        message: "Please enter a valid postal code",
                                      },
                                    })}
                                    name="postal_code"
                                    className="form-control email-input"
                                    placeholder="Postal Code"
                                  />
                                  {errors.postal_code && (
  <span className="text-danger">{errors.postal_code.message}</span>
)}
                                </div>
                                
                              </div>
                             
                                <div className="col-md-6">
                                  <div className="mb-4 cst-input linkedin-icon">
                                    <input
                                      type="url"
                                      id="personal_social_linkedin"
                                      {...register("personal_social_linkedin", {
                                        pattern: {
                                          value: /^(https?:\/\/)?(www\.)?linkedin\.com\/.*$/, // Regular expression for LinkedIn URL
                                          message: "Please enter a valid LinkedIn URL",
                                        },
                                      })}
                                      name="personal_social_linkedin"
                                      className="form-control email-input"
                                      placeholder="LinkedIn URL"
                                    />
                                    {errors.personal_social_linkedin && (
  <span className="text-danger">{errors.personal_social_linkedin.message}</span>
)}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-4 cst-input twitter-icon">
                                    <input
                                      type="url"
                                      id="personal_social_twitter"
                                      {...register("personal_social_twitter", {
                                        pattern: {
                                          value: /^(https?:\/\/)?(www\.)?twitter\.com\/(?:#!\/)?(\w+)\/?$/,
                                          message: "Please enter a valid Twitter profile URL",
                                        },
                                      })}
                                      name="personal_social_twitter"
                                      className="form-control email-input"
                                      placeholder="Twitter URL"
                                    />
                                    {errors.personal_social_twitter && (
  <span className="text-danger">{errors.personal_social_twitter.message}</span>
)}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-4 cst-input facebook-icon">
                                    <input
                                      type="url"
                                      id="personal_social_facebook"
                                      {...register("personal_social_facebook", {
                                        pattern: {
                                          value: /^(https?:\/\/)?(www\.)?(facebook|fb)\.com\/.*$/,
                                          message: "Please enter a valid Facebook profile URL",
                                        },
                                      })}
                                      name="personal_social_facebook"
                                      className="form-control email-input"
                                      placeholder="Facebook URL"
                                    />
                                    {errors.personal_social_facebook && (
  <span className="text-danger">{errors.personal_social_facebook.message}</span>
)}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-4 cst-input instagram-icon">
                                    <input
                                      type="url"
                                      id="personal_social_instagram"
                                      {...register("personal_social_instagram", {
                                        pattern: {
                                          value: /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9_]+$/,
                                          message: "Please enter a valid Instagram profile URL",
                                        },
                                      })}
                                      name="personal_social_instagram"
                                      className="form-control email-input"
                                      placeholder="Instagram URL"
                                    />
                                    {errors.personal_social_instagram && (
  <span className="text-danger">{errors.personal_social_instagram.message}</span>
)}
                                  </div>
                                </div>
                                 </div>  
                              
                                   
                                  </form>
                                </div>
                              </div>
                              <div className="digital-card-action">
                              <button type="submit"  onClick={handleSubmit(onSubmit)} className="btn primary-btn">
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    </>
  );




  
}

export default Official
