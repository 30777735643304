import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ColorRing } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import eye from "../images/eye.svg";
import axios from "axios";
import eyeclose from "../images/eyeclose.svg";
const ResetPassword = ({changePassword}) => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [passwordError, setPasswordError] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const handleVisibilityToggle = (field) => {
    setPasswordVisible((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const validatePassword = (value) => {
    const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/;
    return regex.test(value);
  };

  const notify = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      fontWeight: "bold",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const notify2 = (message1) =>
    toast.error(message1, {
      position: "top-right",
      autoClose: 5000,
      fontWeight: "bold",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const onSubmit = async (data) => {
    if (data.newPassword !== data.confirmPassword) {
      setPasswordError(true);
      return;
    }
  
    if (!validatePassword(data.newPassword)) {
      setPasswordError(true);
      return;
    }
  
    const storedToken = localStorage.getItem('token');
    try {
      setLoading(true);
      console.log(data);
      const dataSend = {
        oldPassword:data.oldPassword,
        password:data.newPassword,
        c_password:data.confirmPassword
     
      };
       const response = await axios.post(changePassword,dataSend,{
        headers: {
          'Authorization': `Bearer ${storedToken}`,
          'Content-Type': 'application/json',
        },
       }); // for api call

       console.log(response.data);
      if(response.data){
        notify("Your Password is Changed successfully");
        reset();
      }
     
      
    } catch (error) {
      console.error(error);
      notify2("Incorrect old password");
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordChange = (e) => {
    if (
      passwordError &&
      (e.target.id === "newPassword" || e.target.id === "confirmPassword")
    ) {
      setPasswordError(false);
    }
  };

  return (
    <>
      {loading && (
        <div className="loading-container">
          <div className="overlay">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-ring-loading"
              colors={["#61b33b", "#61b33b", "#61b33b", "#61b33b", "#61b33b"]}
            />
          </div>
        </div>
      )}
      <ToastContainer />
      <div className="wrapper h-100 py-4 wrapper-top-margin">
        <div className="container">
          {/* <!--Wrapper body--> */}
          <section className="wrapper-body h-100">
            <div className="container-fluid h-100">
              <div className="row h-100">
                <div className="col-md-12 h-100">
                  <div className="main-content h-100">
                    <div className="row h-100 justify-content-center">
                      <div className="col-xl-12 h-100">
                        <div className="digital-card-form h-100 ">
                          <div className="digital-card-inputs">
                            <div className="details">
                              <div className="details-header">
                                <h3>Reset Password</h3>
                              </div>
                              <div className="details-form pt-3">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                  <div className="row">
                                   
                                    <div className="col-md-6">
                                      <div className="mb-4 cst-input password-eye">
                                      <label htmlFor="oldPassword">Old Password <span style={{ color: 'red' }}>*</span></label>
                                        <span
                                          className={`eye-icon ${
                                            passwordVisible.oldPassword
                                              ? "visible"
                                              : ""
                                          }`}
                                          onClick={() =>
                                            handleVisibilityToggle(
                                              "oldPassword"
                                            )
                                          }
                                        >
                                         {passwordVisible.oldPassword ? (
    <img src={eyeclose} alt="close-eye-icon" />
  ) : (
    <img src={eye} alt="eye-icon" />
  )}
                                          
                                        </span>
                                       
                                        <input
                                          type={
                                            passwordVisible.oldPassword
                                              ? "text"
                                              : "password"
                                          }
                                          className="form-control pass-input"
                                          id="oldPassword"
                                          placeholder="Enter your old password"
                                          name="oldPassword"
                                          {...register("oldPassword", {
                                            required: true,
                                          })}
                                          
                                          onChange={handlePasswordChange}
                                          
                                        />
                                        {errors.oldPassword && errors.oldPassword.type === "required" && (
                                          <span className="error-message">
                                            This field is required
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="mb-4 cst-input password-eye">
                                      <label htmlFor="oldPassword">New Password <span style={{ color: 'red' }}>*</span></label>
                                        <span
                                          className={`eye-icon ${
                                            passwordVisible.newPassword
                                              ? "visible"
                                              : ""
                                          }`}
                                          onClick={() =>
                                            handleVisibilityToggle(
                                              "newPassword"
                                            )
                                          }
                                        >
                                       {passwordVisible.newPassword ? (
    <img src={eyeclose} alt="close-eye-icon" />
  ) : (
    <img src={eye} alt="eye-icon" />
  )}
                                        </span>
                                        <input
                                          type={
                                            passwordVisible.newPassword
                                              ? "text"
                                              : "password"
                                          }
                                          className="form-control pass-input"
                                          id="newPassword"
                                          placeholder="Enter your new password"
                                          name="newPassword"
                                          {...register("newPassword", {
                                            required: true,
                                            minLength: 7,
                                            pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/,
                                          })}
                                          onChange={handlePasswordChange}
                                        />
                                        {errors.newPassword && (
                                          <span className="error-message">
                                            This field is required
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="mb-4 cst-input password-eye">
                                      <label htmlFor="oldPassword">Confirm New Password <span style={{ color: 'red' }}>*</span></label>
                                        <span
                                          className={`eye-icon ${
                                            passwordVisible.confirmPassword
                                              ? "visible"
                                              : ""
                                          }`}
                                          onClick={() =>
                                            handleVisibilityToggle(
                                              "confirmPassword"
                                            )
                                          }
                                        >
                                         {passwordVisible.confirmPassword ? (
    <img src={eyeclose} alt="close-eye-icon" />
  ) : (
    <img src={eye} alt="eye-icon" />
  )}
                                        </span>
                                        <input
                                          type={
                                            passwordVisible.confirmPassword
                                              ? "text"
                                              : "password"
                                          }
                                          id="confirmPassword"
                                          {...register("confirmPassword", {
                                            required: true,
                                            minLength: 7,
                                            pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/,
                                          })}
                                          onChange={handlePasswordChange}
                                          className="form-control pass-input"
                                          placeholder="Confirm your new password"
                                        />
                                        {errors.confirmPassword && (
                                          <span className="error-message">
                                            This field is required
                                          </span>
                                        )}
                                        {passwordError && (
                                          <span className="error-message">
                                            Passwords do not match or do not
                                            meet the criteria.
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div className="digital-card-action">
                              <button
                                type="submit"
                                className="btn primary-btn"
                                onClick={handleSubmit(onSubmit)}
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
