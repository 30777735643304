import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "./Button";
import logo from "../images/logo.svg";
import placeholder from "../images/upload-placeholder1.jpg";
import arrowBack from "../images/arrow-back.svg"
const CompanyLogo = ({
  handleSubmit,
  handleNextSection,
  register,
  onSubmit,
  imageSrc2,
  setImageSrc2,
  setOriginalFileName2,
  handleSkipSection
}) => {
 

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    console.log(file);

    if (file) {
    
      const imageUrl = URL.createObjectURL(file);

      setImageSrc2(imageUrl);
    
    }
    setOriginalFileName2(file);
  };

  useEffect(() => {
    // Update the image source when imageSrc changes
    setImageSrc2(imageSrc2);
  }, [imageSrc2]);

  return (
    <>
    <div className="container">
      <section className="wrapper-body">
        <div className="container-fluid h-100">
          <div className="row h-100">
            <div className="col-md-12 h-100">
              <div className="main-content h-100">
                <div className="row h-100 flex-row-reverse">
                  {/* <div className="col-md-4 account-left-bg h-100">
                    <div className="account-left h-100">
                      {/* <!-- <div className="account-visual">
                                    <img src="images/my-card.png" alt="Digital Card" className="img-fluid">
                                </div> --> 
                    </div>
                  </div> */}
                  <div className="col-xl-12 h-100">
                    <div className="digital-card-form h-100">
                      <div className="digital-card-inputs">
                        <div className="site-logo">
                        <Link to="/complete-profile">
                            <img src={logo} alt="Logo" />
                          </Link>
                        </div>
                        <div className="details">
                          <div className="details-header">
                            <h3>Add Company Logo</h3>
                            <p><span style={{ color: 'red' }}>*</span> Please attach a company logo. </p>
                            <Link
                              onClick={handleSubmit(() =>
                                handleSkipSection(false)
                              )}
                            >
                              Skip
                            </Link>
                          </div>
                          <div className="details-form">
                            <span className="text-center d-block mb-3">
                              Upload image
                            </span>
                            <form onSubmit={handleSubmit(onSubmit)}>
                              <div className="image-upload">
                                <img
                                  id="uploadimg"
                                  src={imageSrc2 || placeholder}
                                  alt="your image"
                                />
                                <input
                                  type="file"
                                  id="company_logo"
                                  onChange={(e) => {
                                    handleImageChange(e);
                                    register("company_logo"); // Assuming register is passed as a prop
                                  }}
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="digital-card-action">
                          <button onClick={() => handleNextSection(true)} className="btn primary-btn">
                            <img src={arrowBack} alt="arrow-left"/> Back
                          </button>
                      
                          <Button
                            onClick={handleSubmit(() =>
                             handleNextSection(false)
                            )}
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
    </>
  );
};

export default CompanyLogo;
