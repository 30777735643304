import React, { useEffect, useLayoutEffect } from "react";
import MobileDetect from "mobile-detect";
import logo from "../images/logo.svg";
import logo2 from "../images/card-white-logo.svg";
import companyLogo from"../images/company-logo.png";
import young from "../images/default-user-img.webp";
import envelope from "../images/envelope-green.svg";
import globe from "../images/globe-green.svg";
import phone from "../images/phone-green.svg";
import facebook from "../images/facebook-green.svg"
import linkedin from "../images/linkedin-green.svg";
import twitter from "../images/twitter-green.svg";
import instagram from "../images/instagram-green.svg";
import { Link, useNavigate, useParams,  } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
const CardDetails = ({detailUrl,contacts}) => {
  
  const [cardDetails,setCardDetails]=useState({});

  const image='https://mydigitalcard-admin.kitlabs.us'
  const id = getParameterByName("id");
 console.log("dataregister",id)
  useEffect(()=>{
      
       detail();
  },[])  

 const navigate = useNavigate();
  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  async function detail() {
    const storedToken = localStorage.getItem('token');
    try {
      const response = await axios.get(`${detailUrl}?id=${id}`, {
        headers: {
          'Authorization': `Bearer ${storedToken}`
        }
      });

      console.log(response.data);
      setCardDetails(response.data.data);
    } catch (error) {
      console.error("Error in Axios request:", error);
    }
  }
  

  


const handleAddToContact = async () => {
 
    const { isMobile, platform } = isMobileDevice();
    console.log("I am here1")
    if (isMobile) {
      console.log("I am here")
      if (platform === 'androidos') {
        console.log("android");
        // Android platform specific behavior
        window.location.href = `https://mydigitalcardapp.page.link/?link=https://mydigitalcard.kitlabs.us?id=${id}&apn=com.kitlabs.my_digital_card&ibi=com.kitlabs.MyDigitalCard&_imcp=1`
      } else if (platform === 'ios') {
        // iOS platform specific behavior
        console.log("ios");
         window.location.href=(`https://mydigitalcardapp.page.link/?link=https://mydigitalcard.kitlabs.us/my-digital-card?id=${id}&apn=com.kitlabs.my_digital_card&isi=6477707963&ibi=com.kitlabs.MyDigitalCard&_imcp=1
        `)
      }
    
  
  } else {
    // Make a POST request to add the card details to contacts
    try {
      const storedToken = localStorage.getItem('token');
      const response = await axios.post(contacts, {
        // Add the data you want to send in the POST request body
        // For example:
        linked_business_card_id: cardDetails.id,
        
      }, {
        headers: {
          'Authorization': `Bearer ${storedToken}`
        }
      });
      console.log(response.data);
      // Handle the response as needed

      // Generate and download vCard file
      const vcardData = generateVCardData(cardDetails.first_name, cardDetails.last_name, cardDetails.phone_1, cardDetails.email);
      downloadVCard(vcardData);
    } catch (error) {
      console.error("Error in POST request:", error);
      navigate(`/sign-up?id=${id}`);
    }
  }
};


 


  const generateVCardData = (first_name, last_name, phone_1, email) => {
    return `BEGIN:VCARD\r\nVERSION:3.0\r\nFN:${first_name} ${last_name}\r\nTEL:${phone_1}\r\nEMAIL:${email}\r\nEND:VCARD`;
  };
  const downloadVCard = (vcardData) => {
    const blob = new Blob([vcardData], { type: "text/vcard" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");                                       
    link.href = url;
    link.setAttribute("download", "contact.vcf");
    link.click(); 
  };
// MobileViewDetect function
  // const isMobileDevice = () => {
  //   const md = new MobileDetect(window.navigator.userAgent);
  //   return md.mobile() !== null || md.tablet() !== null;
  // };     
  const isMobileDevice = () => {
    const md = new MobileDetect(window.navigator.userAgent);
    const platform = md.os(); // Get the platform information
    console.log("Platform:", platform);

    return {
      isMobile: md.mobile() !== null || md.tablet() !== null,
      platform: platform ? platform.toLowerCase() : null // Return platform information in lowercase
    };
  };
  

  


  return (
    <div className="wrapper">
    <div className="container">
    <section className="wrapper-body">
    <div className="container-fluid h-100">
        <div className="row h-100">
            <div className="col-md-12 h-100">
                <div className="main-content h-100">
                    <div className="row h-100 flex-row-reverse abstract-bg">
                        <div className="col-md-12 h-100">
                            <div className="digital-card-form h-100">
                                <div className="digital-card-inputs">
                                    <div className="site-logo">
                                     <a href="sign-in.html">{isMobileDevice() ?<img src={logo} alt="Logo"/>:<img src={logo2} alt="Logo"/>}</a>
                                    </div>
                                    <div className="details d-flex align-items-center justify-content-center mt-2">
                            <div className="card-details">
                              <div className="card-detail-logo">
                                <img src={companyLogo} alt="" />
                              </div>
                              <div className="card-detail-visual">
                              <img src={cardDetails.head_shot ? `${image}${cardDetails.head_shot}` : young} alt="" />
                              </div>
                              <div className="card-detail-header">
                                <h3>{cardDetails.first_name} {cardDetails.last_name}</h3>
                                <p>{cardDetails.job_role_title}</p>
                              </div>
                              <div className="card-detail-body">
                                <div className="card-detail-contact">
                                  {cardDetails.email && <Link to=""><span><img src={envelope} alt="" /></span>{cardDetails.email}</Link>}
                                  {cardDetails.website && <Link to=""><span><img src={globe} alt="" /></span>{cardDetails.website}</Link>}
                                  {cardDetails.phone_1 && <Link to=""><span><img src={phone} alt="" /></span>{cardDetails.phone_1}</Link>}
                                </div>
                                <div className="card-detail-social">
                                  {cardDetails.personal_social_facebook &&<Link to=""><span><img src={facebook} alt="" /></span>{cardDetails.personal_social_facebook}</Link>}
                                  {cardDetails.personal_social_linkedin &&<Link to=""><span><img src={linkedin} alt="" /></span>{cardDetails.personal_social_linkedin}</Link>}
                                  {cardDetails.personal_social_twitter &&<Link to=""><span><img src={twitter} alt="" /></span>{cardDetails.personal_social_twitter}</Link>}
                                  {cardDetails.personal_social_instagram &&<Link to=""><span><img src={instagram} alt="" /></span>{cardDetails.personal_social_instagram}</Link>}
                                </div>
                              </div>
                            </div>
                                       <div className="card-action text-center create-btn button ">
                                       <button className="btn primary-btn" onClick={handleAddToContact}>Add to Contact</button><br/><br/>
                                       <Link to={`https://mydigitalcardapp.page.link/?link=https://mydigitalcard.kitlabs.us/my-digital-card?id=${id}&apn=com.kitlabs.my_digital_card&isi=6477707963&ibi=com.kitlabs.MyDigitalCard&_imcp=1`} className="btn primary-btn" >Create My Card</Link><br/><br/>
                                       
                                       </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</div>
</div>
  );
};

export default CardDetails;
