import React from 'react'
import logo from "../images/logo.svg";
const Privacy = () => {
  return (
    <div className="wrapper">
    <section className="wrapper-body privacy" >
      <div className="container-fluid mt-2">
        <div className="row h-100">
          <div className="col-md-12 h-100">
            <div className="main-content h-100">
              <div className="row h-100 flex-row-reverse">
                {/* <div className="col-md-4 account-left-bg h-100">
                <div className="account-left h-100">
                  {/* <!-- <div className="account-visual">
                                  <img src="images/my-card.png" alt="Digital Card" className="img-fluid">
                              </div> --> 
                </div>
              </div> */}
                <div className="col-xl-12 h-100">
                  <div className="digital-card-form h-100">
                    <div className="digital-card-inputs">
                      <div className="site-logo">
                      <a to="/complete-profile">
                          <img src={logo} alt="Logo" />
                        </a>
                      </div>
                      <div className="details">
                        <div className="details-header">
                          
                         
                         
                        </div>
                       <div className="details-form ">
                       <h3>Privacy Policy</h3>
  <p>We at <strong style={{color:"green"}}>My Digital Card</strong> are committed to protecting and respecting your privacy. We collect and process your personal information in accordance with the IT Act, 2000, and other national and state laws related to personal data processing. Please read the following carefully to understand our views and practices regarding your personal data.</p>
  
  <h3>Information we Collect</h3>
  <p><strong>a)</strong> The specific personal information you provide while using our site includes: First name, last name, job title, email address, mobile number, postcode, preferences, and interests.</p>
  <p><strong>b)</strong> We automatically collect information using "Cookies." Cookies are small data files stored on your hard drive by websites. Among other things, cookies help us improve our site, our marketing activities, and your experience. We use cookies to see which areas and features are popular and to count visits to our site.</p>
  <p><strong>c)</strong> By using this website, you agree that we may advertise your feedback on the Website and marketing materials.</p>

  <h3>How we use this information</h3>
  <p><strong>a)</strong> We require this information to enhance our products and services, particularly to personalize the webpage according to your needs.</p>
  <p><strong>b)</strong> From time to time, we may use your information to contact you for market research purposes via email, phone, or fax.</p>
  <p><strong>c)</strong> We primarily use the collected information to provide, maintain, protect, and improve our current products and services.</p>
  <p><strong>d)</strong> We may use your information to improve our services, site, and how we operate our business.</p>
  <h3>Cookies</h3>
  <p><strong>a)</strong> To optimize our web presence, we use cookies, which are small text files stored on your computer's main memory. This allows us to improve your access to our site.</p>
  <p><strong>b)</strong> Approved third parties may also set cookies when you interact with our services.</p>
  <p><strong>c)</strong> Third parties include search engines, providers of measurement and analytics services, social media networks, and advertising companies.</p>

  <h3>Changes to this Policy</h3>
  <p>We may change this policy from time to time. Your continued use of our services after such changes have been published will constitute your acceptance of the revised policy. If you have any privacy concerns or grievances, please contact us with a thorough description.</p>
</div>

                      </div>
                  
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
 
  )
}

export default Privacy
