import React, { useEffect, useState } from "react";
import young from "../images/young-man.jpg";
import search from "../images/Search.png";
import axios from "axios";
import { Link } from "react-router-dom";
const MyContacts = ({MyContact}) => {

  const [contactData, setContactData] = useState([]);
  const image='https://mydigitalcard-admin.kitlabs.us';
  
  const fetchData = async () => {
    const storedToken = localStorage.getItem('token');
    try {
      const response = await axios.get(MyContact,{
        headers: {
          'Authorization': `Bearer ${storedToken}`,
          'Content-Type': 'application/json',
        },
      });
      console.log(response.data.data);
      if (Array.isArray(response.data.data)) {
        setContactData(response.data.data);
      } else {
        console.error("Error fetching contact data: Response data is not an array");
      }
    } catch (error) {
      console.error("Error fetching contact data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="wrapper h-100% py-4 wrapper-top-margin">
      <div className="container h-100 ">
        <section className="wrapper-body h-auto pb-4">
          <div className="container-fluid h-100">
            <div className="row h-100">
              <div className="col-md-12 h-100">
                <div className="main-content h-100">
                  <div className="row h-100 justify-content-center">
                    <div className="col-xl-12 h-100">
                      <div className="dashborad h-100">
                        <div className="row justify-content-center">
                          <div className="col-12 col-md-10">
                            <div className="details">
                              <div className="details-header">
                                <h3>Contact List</h3>
                                
                                <div className="container ">
                                  { contactData.length === 0 ? (
    <p>No contacts available</p>
  ) :contactData.map(contact => (
                                    <div key={contact.id} className="card">
                                   
                                      <div className="card-body p-2">
                                        <div className="details-header d-flex justify-content-between align-items-center text-center">
                                          <div className="user-pic d-flex">
                                            <span>
                                              <img src={`${image}${contact.head_shot}`} alt={contact.first_name} />
                                            </span>
                                            
                                            <div className="d-blox ms-3">
                                           
                                              <h5>{contact.first_name} {contact.last_name}</h5>
                                              <p className="m-0 text-start">
                                                 {contact.job_role_title}
                                              </p>
                                           
                                            </div>
                                          </div>
                                          <div className="user-name">
                                          <p className="d-flex align-items-center mb-0 justify-content-center">
                                            {contact.phone_1}
                                          </p>
                                        </div>
                                        </div>
                                      </div>
                                      
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );

};

export default MyContacts;
{
  /*  */
}
