import { BrowserRouter, Routes, Route, Navigate, } from "react-router-dom";
import ContactForm from "./Component/ContactForm";
import Registration from "./Component/Registration";
import Login from "./Component/Login";
import Nopage from "./Component/Nopage";
import QRCode from "./Component/QRCode";
import Welcome from "./Component/Welcome";
import Email from "./Component/Email";
import ForgotPassword from "./Component/ForgotPassword";
import CardDetails from "./Component/CardDetails";
import { useEffect, useState } from "react";
// import Layout from "./Component/Layout";
import ScanQr from "./Component/ScanQr";
import Layout from "./Component/Layout";
import ContactUs from "./Component/ContactUs";
import ResetPassword from "./Component/ResetPassword";
import Personal from "./Component/Personal";
import Official from "./Component/Official";
import MyContacts from "./Component/MyContacts";
import News from "./Component/News";
import NewList from "./Component/NewList";
import MyDigitalCard from "./Component/MyDigitalCard";
import Privacy from "./Component/Privacy";
import AccountDelete from "./Component/AccountDelete";

function App() {

  console.log("app is rendreing");

  const url = "https://mydigitalcard-admin.kitlabs.us/api";
  const detailUrl="https://mydigitalcard.kitlabs.us/api/digital-card";
  const dataUrl="https://mydigitalcard-admin.kitlabs.us/api/digital-card";
  const login = `${url}/login`;
  const socialRegister = `${url}/social_register`;
  const register = `${url}/register`;
  const resetPassword = `${url}/create`;
  const personalUrl=`${url}/personal-details`;
  const officalUrl=`${url}/official-details`;
  const updatePersonal=`${url}/update-personal`;
  const updateOffical=`${url}/update-official`;
  const contactUs=`${url}/contact-us`;
   const changePassword=`${url}/changePassword`;
  const MyContact=`${url}/my-contact`;
  const contacts=`${url}/contacts`;
  


  const [access, setAccess] = useState(
    () => localStorage.getItem("token") || null
  );
  
 
  const [path, setPath] = useState();
  

  // Effect to update access state when the component mounts
  useEffect(() => {
    const storedToken = localStorage.getItem("token");
  
    if (storedToken) {
      setAccess(storedToken);
    }
   
  }, []);

  function parent(data) {
    console.log("data", data);
  
      localStorage.setItem("token", data);
      setAccess(data);
   
     
  }
  
  

  

  return (
    <BrowserRouter>
      <Routes>
  
<Route
          path="/"
          element={
            access ?
        
              // If user is logged in, redirect based on messageLogin
                (
                 
                <Navigate to="/complete-profile" replace />
              ) 
            : (
              // If user is not logged in, show the Login component
              <Login parent={parent} login={login} socialRegister={socialRegister} detailUrl ={dataUrl} contacts={contacts} />
            )
          }
        />


      
      
     
        <Route
          path="/forgot-password"
          element={<Email resetPassword={resetPassword} />}
        />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route
          path="/sign-up"
          element={ access ? (
            <Navigate to="/welcome" replace />
          ) : <Registration parent={parent} registerUrl={register} detailUrl ={dataUrl} contacts={contacts}/>}
        />
        {/* // In your main component */}
        <Route
  path="/welcome"
  element={access ? <Welcome />:<Navigate to="/" replace />}
/>

        <Route
          path="/complete-profile"
          element={
            access ? (
              <ContactForm setPath={setPath} officalUrl={officalUrl}/>
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/qrCode"
          element={
            access ? (
              <QRCode url={url} path={path} />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/my-digital-card"
          element= {<CardDetails  detailUrl ={dataUrl}contacts={contacts}/>} 
        />
        <Route
          path="/dashboard"
          element={
            access ? (
              <Layout>
                <ScanQr officalUrl={officalUrl}/>
              </Layout>
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
          <Route
          path="/my-card"
          element={
            access ? (
              <Layout>
                <MyDigitalCard officalUrl ={officalUrl}/>
              </Layout>
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/personal-details"
          element={
            access ? (
              <Layout>
                <Personal personalUrl={personalUrl} updatePersonal={updatePersonal}/>
              </Layout>
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/contact-us"
          element={
            access ? (
              <Layout>
                <ContactUs contactUs={contactUs}/>
              </Layout>
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/official-details"
          element={
            access ? (
              <Layout>
                <Official officalUrl={officalUrl} updateOffical={updateOffical}/>
              </Layout>
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/myContact"
          element={
            access ? (
              <Layout>
                <MyContacts MyContact={MyContact} />
              </Layout>
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/resetpassword"
          element={
            access ? (
              <Layout>
                <ResetPassword changePassword={changePassword}/>
              </Layout>
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/news"
          element={
            access ? (
              <Layout>
                <News />
              </Layout>
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/newslist"
          element={
            access ? (
              <Layout>
                <NewList />
              </Layout>
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
         <Route path="/privacy-policy" element={<Privacy/>} />
         <Route path="/account-delete" element={<AccountDelete/>} />
        <Route path="*" element={<Nopage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
