import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { ColorRing } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactUs = ({ contactUs }) => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const notify = (message, type) =>
    toast[type](message, {
      position: "top-right",
      autoClose: 5000,
      fontWeight: "bold",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: type === "success" ? "light" : "dark",
    });

  const onSubmit = async (data) => {
    const storedToken = localStorage.getItem("token");
    try {
      setLoading(true);
      const response = await axios.post(contactUs, data, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      });
      if (response.data) {
        notify("Thank you for contacting us", "success");
        reset();
      }
    } catch (error) {
      console.error(error);
      notify("Something went wrong", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && (
        <div className="loading-container">
          <div className="overlay">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-ring-loading"
              colors={["#61b33b", "#61b33b", "#61b33b", "#61b33b", "#61b33b"]}
            />
          </div>
        </div>
      )}
      <ToastContainer />
      <div className="wrapper h-100 py-4 wrapper-top-margin">
        <div className="container ">
          <section className="wrapper-body h-100">
            <div className="container-fluid h-100">
              <div className="row h-100">
                <div className="col-md-12 h-100">
                  <div className="main-content h-100">
                    <div className="row h-100 justify-content-center">
                      <div className="col-xl-12 h-100">
                        <div className="digital-card-form h-100 ">
                          <div className="digital-card-inputs">
                            <div className="details">
                              <div className="details-header">
                                <h3>Get in Touch with Us</h3>
                              </div>
                              <div className="details-form pt-3">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="mb-4 cst-input user">
                                        <label htmlFor="name">
                                          Name{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control email-input"
                                          id="name"
                                          {...register("name", {
                                            required: true,
                                            pattern: /^[A-Za-z\s]+$/,
                                          })}
                                          placeholder="Enter your name"
                                          name="name"
                                        />
                                        {errors.name &&
                                          errors.name.type === "required" && (
                                            <span className="text-danger">
                                              Name is required
                                            </span>
                                          )}
                                        {errors.name &&
                                          errors.name.type === "pattern" && (
                                            <span className="text-danger">
                                              Name must contain only letters
                                            </span>
                                          )}
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="mb-4 cst-input">
                                        <label htmlFor="email">
                                          Email{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="email"
                                          className="form-control email-input"
                                          id="email"
                                          {...register("email", {
                                            required: true,
                                            pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                          })}
                                          placeholder="Enter your email"
                                          name="email"
                                        />
                                        {errors.email &&
                                          errors.email.type === "required" && (
                                            <span className="text-danger">
                                              Email is required
                                            </span>
                                          )}
                                        {errors.email &&
                                          errors.email.type === "pattern" && (
                                            <span className="text-danger">
                                              Invalid email format
                                            </span>
                                          )}
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="mb-4 cst-input subject">
                                        <label htmlFor="subject">
                                          Subject{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control email-input"
                                          id="subject"
                                          {...register("subject", {
                                            required: true,
                                            pattern: /^[A-Za-z]+$/i,
                                          })}
                                          placeholder="Enter subject"
                                          name="subject"
                                        />
                                        {errors.subject &&
                                          errors.subject.type ===
                                            "required" && (
                                            <span className="text-danger">
                                              Subject is required
                                            </span>
                                          )}
                                        {errors.subject &&
                                          errors.subject.type === "pattern" && (
                                            <span className="text-danger">
                                              Subject must contain only letters
                                            </span>
                                          )}
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="mb-4 cst-input phone">
                                        <label htmlFor="phone_number">
                                          Phone No.{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="tel"
                                          className="form-control email-input"
                                          id="phone_number"
                                          {...register("phone_number", {
                                            required: true,
                                            pattern: /^[0-9]+$/,
                                          })}
                                          placeholder="Enter your phone no"
                                          name="phone_number"
                                        />
                                        {errors.phone_number &&
                                          errors.phone_number.type ===
                                            "required" && (
                                            <span className="text-danger">
                                              Phone number is required
                                            </span>
                                          )}
                                        {errors.phone_number &&
                                          errors.phone_number.type ===
                                            "pattern" && (
                                            <span className="text-danger">
                                              Phone number must contain only
                                              numbers
                                            </span>
                                          )}
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="mb-4 cst-input message">
                                        <label htmlFor="message">
                                          Message{" "} <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </label>
                                        <textarea
                                          className="form-control"
                                          rows="4"
                                          id="message"
                                          name="message"
                                          {...register("message", {
                                            required: true,
                                          })}
                                          placeholder="Enter your message"
                                        ></textarea>
                                        {errors.message && (
                                          <span className="text-danger">
                                            Message is required
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="digital-card-action">
                                    <button
                                      type="submit"
                                      className="btn primary-btn"
                                    >
                                      Send
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
